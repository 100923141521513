import React,{useState, useContext} from 'react';
import { Badge, Card } from 'react-bootstrap';
import { FaAward } from 'react-icons/fa';
import { useNavigate,useLocation } from 'react-router-dom';
import { AppContext } from '../App';
import { initializeApp } from 'firebase/app';
import { getFirestore , collection ,query, where, getDocs } from "firebase/firestore";
function GameCard(props) {
    const {state, dispatch} = useContext(AppContext);
    const [sponsoredImage, setSponsoredImage]= useState('');
   
    const navigate = useNavigate(); 
    const location = useLocation();
   
    const gameClicked=(game) => {
        // console.log("Dashboard gameClicked",game,{game_selected_id:game.gameId,game_selected:game});
        dispatch({type:'game_state_update', payload:{game_selected_id:game.gameId,game_selected:game}});
        navigate(`/${game.gameId}`,{id:game.gameId})
    }
    
    let game = props.game;
  

    if(game.isSponsored){
        const firebaseConfig = {
            apiKey: "AIzaSyBVD7__S8tFmYBHcA2R47-ONtxfMGLDzbs",
            authDomain: "taccoin.firebaseapp.com",
            projectId: "taccoin",
            storageBucket: "taccoin.appspot.com",
            messagingSenderId: "609150439089",
            appId: "1:609150439089:web:ac7b7b3b392a83c41c5c90",
            measurementId: "G-5YFNF35T96"
          };
          
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
       
        const q = query(collection(db, "sponsored"), where("gameID", "==", Number(game.gameId)));
        getDocs(q).then((querySnapshot)=>{
            querySnapshot.forEach((doc) => {
                setSponsoredImage(`https://firebasestorage.googleapis.com/v0/b/taccoin.appspot.com/o/${doc.data().gameImage}?alt=media`);
                
            });
        });
        
       

        if(location.pathname.toLowerCase()==="/my-games"){
            return(
                <Card className={game.isFinished || game.isExpired ? "bg-c-gray" : "bg-c-yellow"} key={game.gameId} onClick={()=>gameClicked(game)} 
                      style={{width:300, borderColor: (game.isFinished || game.isExpired)?"#b8b8b8":'#ffd18e', margin:13, cursor:'pointer'}}>
                    <Card.Body >
                        <div className="d-flex justify-content-between align-items-center">
                            <span>No: {game.gameId}</span>
                            <h4><Badge className="bg-secondary">{game.tokenSymbol}</Badge></h4>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            <h1 style={{fontSize:'50px'}}><FaAward color="#a0890d" /></h1>
                            <span style={{fontSize:30, color:'#605d5d'}}>{((game.amount)/10**game.tokenDecimals)}</span>
                        </div>
                        <Card.Subtitle className="mb-2 text-muted" ><span className="btn btn-success btn-sm" style={{fontSize:12, color:'#fff',marginTop:5}}>
                    Status: 
                    {game.score1===2 && game.address1.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Won':null }    
                    {game.score2===2 && game.address2.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Won':null } 
                    {game.score2===2 && game.address1.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Lost':null }    
                    {game.score1===2 && game.address2.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Lost':null } 
                    {game.score1<2 && game.score2<2 && game.isFinished && game.address2!=='0x0000000000000000000000000000000000000000' ? 'Penalty':null } 
                    {game.score1<2 && game.score2<2 && !game.isFinished && game.address2!=='0x0000000000000000000000000000000000000000'? 'Playing Now':null }
                    {game.score1<2 && game.score2<2 && game.isFinished && game.address2==='0x0000000000000000000000000000000000000000'? 'Closed':null }
                    {(game.score1===2 && game.address1.toLowerCase()===state.wallet_address.toLowerCase()) || (game.score2===2 && game.address2.toLowerCase()===state.wallet_address.toLowerCase()) 
                    ? game.isClaimed ? ',Claimed':',Waiting for Claim' : null}   
                    </span></Card.Subtitle>
                    </Card.Body>
                </Card>
            )
        } else 
            return (
                <Card className={game.isFinished || game.isExpired ? "bg-c-gray" : "bg-c-yellow"} key={game.gameId} onClick={()=>gameClicked(game)} 
                      style={{width:300, borderColor: (game.isFinished || game.isExpired)?"#b8b8b8":'#ffd18e', margin:13, cursor:'pointer'}}>
                    <Card.Body >
                        <img alt="Sponsored" style={{maxWidth:'100%', marginBottom:10}} src={sponsoredImage} onError={({ currentTarget }) => {currentTarget.onerror=null; currentTarget.src="./sponsored/default.png";}} />
                        <div className="d-flex justify-content-center align-items-center">
                            <h1 style={{fontSize:'50px'}}><FaAward color="#a0890d" /></h1>
                            <span style={{fontSize:30, color:'#605d5d', marginRight:10}}>{((game.amount)/10**game.tokenDecimals)}</span>
                            <h4><Badge className="bg-secondary">{game.tokenSymbol}</Badge></h4>
                        </div>
                        <Card.Subtitle className="mb-2 text-muted" >Entrance Fee: FREE
                        <p style={{marginTop:'5px'}}>
                        <span className="btn btn-success btn-sm" style={{fontSize:12, color:'#fff',marginTop:5}}>
                        {game.address2!=='0x0000000000000000000000000000000000000000' ?
                        game.address1.toLowerCase()===state.wallet_address.toLowerCase() || game.address2.toLowerCase()===state.wallet_address.toLowerCase() ? 'You have qualified to enter this game 🎉' : 
                        'Playing Now'
                        :'Request to Join Game'
                        }
                        </span>
                        </p>
                        </Card.Subtitle>                                        
                    </Card.Body>
                </Card>
            )
    }
    if(location.pathname.toLowerCase()==="/my-games"){
    return (
        <Card className={game.score1<2 && game.score2<2 && !game.isFinished && game.address2!=='0x0000000000000000000000000000000000000000' ? "bg-c-blue" : "bg-c-green"} key={game.gameId} onClick={()=>gameClicked(game)} 
              style={{width:300, borderColor: game.expireTime===game.createTime && !game.isFinished ?"#b8b8b8":'#81ffe5', margin:13, cursor:'pointer'}}>
            <Card.Body >
                <div className="d-flex justify-content-between align-items-center">
                    <span>No: {game.gameId}</span>
                    <h4><Badge className="bg-secondary">{game.tokenSymbol}</Badge></h4>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <h1 style={{fontSize:'50px'}}><FaAward color="#eac600" /></h1>
                    <span style={{fontSize:30, color:'#605d5d'}}>{((game.amount*2)/10**game.tokenDecimals)
                    }</span>
                </div>
                <Card.Subtitle className="mb-2 text-muted" >
                <span className="btn btn-success btn-sm" style={{fontSize:12, color:'#fff',marginTop:5}}>
                    Status: 
                    {game.expireTime===game.createTime && !game.isFinished ? ' Waiting for Opponent':null }
                   
                    {game.score1===2 && game.address1.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Won':null }    
                    {game.score2===2 && game.address2.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Won':null } 
                    {game.score2===2 && game.address1.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Lost':null }    
                    {game.score1===2 && game.address2.toLowerCase()===state.wallet_address.toLowerCase() ? ' You Lost':null } 
                    {game.score1<2 && game.score2<2 && game.isFinished && game.address2!=='0x0000000000000000000000000000000000000000' ? 'Penalty':null } 
                    {game.score1<2 && game.score2<2 && !game.isFinished && game.address2!=='0x0000000000000000000000000000000000000000'? 'Playing Now':null }
                    {game.score1<2 && game.score2<2 && game.isFinished && game.address2==='0x0000000000000000000000000000000000000000'? 'Closed':null }
                    {(game.score1===2 && game.address1.toLowerCase()===state.wallet_address.toLowerCase()) || (game.score2===2 && game.address2.toLowerCase()===state.wallet_address.toLowerCase()) 
                    ? game.isClaimed ? ',Claimed':',Waiting for Claim' : null}   
                    </span>
                  
                </Card.Subtitle>
            </Card.Body>
        </Card>
    )
    }else
    {
        return (
            <Card className={game.address2!=='0x0000000000000000000000000000000000000000' ? "bg-c-blue" : "bg-c-green"} key={game.gameId} onClick={()=>gameClicked(game)} 
                  style={{width:300, borderColor: (game.isFinished || game.isExpired)?"#b8b8b8":'#81ffe5', margin:13, cursor:'pointer'}}>
                <Card.Body >
                    <div className="d-flex justify-content-between align-items-center">
                        <span>No: {game.gameId}</span>
                        <h4><Badge className="bg-secondary">{game.tokenSymbol}</Badge></h4>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <h1 style={{fontSize:'50px'}}><FaAward color="#eac600" /></h1>
                        <span style={{fontSize:30, color:'#605d5d'}}>{((game.amount*2)/10**game.tokenDecimals)} {game.tokenSymbol}</span>
                    </div>
                    <Card.Subtitle className="mb-2 text-muted" >
                    Entrance Fee: {(game.amount/10**game.tokenDecimals)} {game.tokenSymbol}
                        <p style={{marginTop:'5px'}}>
                        <span className="btn btn-success btn-sm" style={{fontSize:12, color:'#fff',marginTop:5}}>
                        {game.address2!=='0x0000000000000000000000000000000000000000' ?
                        'Playing Now'
                        :'Join Game'
                        }
                        </span>
                        </p>
                         </Card.Subtitle>
                </Card.Body>
            </Card>
        ) 
    }
}
export default GameCard;
import React, { useState, useEffect, useContext } from "react";
import {
  Alert,
  Spinner,
  Card
  } from "react-bootstrap";

import { AppContext, tac_contract } from "../App";

import { convertHexToBigInt, delay, objGame } from "../common/const";
import GameCard from "../common/GameCard";

export default function MyGamesPage() {
  const { state } = useContext(AppContext);
  

  const [myGameList, setMyGameList] = useState([]);
  const [gamesFetched, setGamesFetched] = useState(false);
  const [errorMessage, setError] = useState(null);

  useEffect(() => {
    // console.log("MyGamesPage useEffect");
    setInterval(async () => {
      await fetch_MyGetGames();
    }, 2000);
  }, []);

  const fetch_MyGetGames = async () => {
    
    try {
      let games = [];

      while (!tac_contract) await delay(1000);

      if (tac_contract) {
        let n = await tac_contract.getGamesPlayer(state.wallet_address,10);
        n.map((x) => {
          if (Number(convertHexToBigInt(x[0])) > 0) games.push(objGame(x));
        });
        setMyGameList(games);
        setGamesFetched(true);
        setError(null);
        // console.log("Games Fetched");
      }
    } catch (e) {
      setError("Error getting your games");
      /*
        dispatch({
        type: "toast",
        payload: {
          toast_bg: "danger",
          toast_header: "Error",
          toast_show: true,
          toast_message: "Error getting your games !",
        },
      });
      */
      // console.log(e.message);
    }
  };

  return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
      <Card
        border="success"
        style={{ width: "90%", backgroundColor: "rgba(255,255,255,0.8)" }}
      >
        <Card.Header as="h5">My Games</Card.Header>

        {state.wallet_state === "NOT_CONNECTED" ? (
          <Alert className="w-100" variant="danger">
            {" "}
            Wallet not connected!
          </Alert>
        ) :
        gamesFetched===true ?
        (
        
        myGameList.length == 0 ? 
        (
          <Alert className="w-100" variant="info">
            {" "}
            Games not found!
          </Alert>
        ) : 
        (
          <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
            {myGameList.map((game, i) => (
              <GameCard key={i} game={game} />
            ))}
          </Card.Body>
        )
        ) :
        (
          <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
            <Spinner size="sm" animation="grow" />
          </Card.Body>
        )
      }
      </Card>
      <div
        className="footer fixed-bottom d-flex flex-wrap align-items-center justify-content-around p-1"
        style={{ minHeight: 30, color: "red" }}
      >
        {errorMessage ? errorMessage : null}
      </div>
    </div>
  );
}

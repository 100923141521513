import React, { useState, useEffect, useContext, useRef } from "react";
import ConfettiExplosion from 'confetti-explosion-react';
import {  Alert, Spinner, Button,Card, } from "react-bootstrap";
import { delay } from "../common/const";
import videoTrue from '../media/videos/true.mp4';
import videoFalse from '../media/videos/false.mp4';
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";


let exTime  = -1;
let score1_ =  0;
let score2_ =  0;

export default function DemoGamePage() {
    const navigate = useNavigate(); 

    const refScore1 = useRef();
    const refScore2 = useRef();


    const [videoSrc, setvideoSrc]         = useState(null);
    const [lastEstimate, setLastEstimate] = useState(null);
    const [expireTime, setExpireTime]     = useState(0);
    const [score1, setScore1]             = useState(0);
    const [score2, setScore2]             = useState(0);
    const [isExpired, setIsExpired]       = useState(false);
    const [isFinished, setIsFinished]     = useState(false);
    const [isStarted, setIsStarted]       = useState(false);
    
    const [btnStartShow, setBtnStartShow] = useState(true);
    const [btnPlayShow,  setBtnPlayShow]  = useState(false);
    const [btnWaitShow,  setBtnWaitShow]  = useState(false);
    const [btnPlayGameTrueDisabled, setBtnPlayGameTrueDisabled]   = useState(false);
    const [btnPlayGameFalseDisabled, setBtnPlayGameFalseDisabled] = useState(false);
    const [currentGamer,setCurrentGamer] = useState("");
    const [isExploding, setIsExploding] = useState(false);
    const refreshPage = () => {
        navigate(0);
    }

    useEffect(() => {
        // console.log("DemoGamePage useEffect");

        let interval = setInterval(()=>{
            expTime();
        }, 1000);
        
        return () => { 
            // console.log("Oyundan Çıktık");
            setvideoSrc(null);
            setExpireTime(-1);
            setBtnPlayGameFalseDisabled(false);
            setBtnPlayGameTrueDisabled(false);
            clearInterval(interval);
            exTime=-1;
            score1_=0;
            score2_=0;
            setBtnStartShow(true);
            setBtnPlayShow(true);
            setBtnWaitShow(true);
            setScore1(0);
            setScore2(0);
            setIsStarted(false);
            setIsFinished(false);
            setIsExpired(false);
            setLastEstimate(null);
            
        };
      }, []);

    useEffect(()=>{
        if(score1>0 || score2>0 ) setTimeout(()=>{gsap.to(refScore1.current, { rotation: "+=360" })},10000);
    },[score1]);
    
    useEffect(()=>{
        if(score1>0 || score2>0 ) setTimeout(()=>{gsap.to(refScore2.current, { rotation: "+=360" })},10000);
    },[score2])

    const getRandomTF = () =>{
        let r = (((new Date().getTime()/1000).toString().slice(-1))+Math.floor(Math.random() * 10))%2;
        return r===1?false:true
    }

    const expTime = ()=>{
        if(exTime>0 && !btnWaitShow) {
            --exTime;
            setExpireTime(exTime);
        } else {
            if(exTime===0){
                setBtnPlayShow(false);
                setIsExpired(true);
                exTime=-1;
                setExpireTime(-1);
            }
        }
    }

    const startGameClicked = ()=>{
        setBtnStartShow(false);
        setBtnPlayShow(true);
        setIsStarted(true);
        setExpireTime(120);
        exTime = 120;
    }

    const playGame = async(choosed,side="PERSON") => { //choosed=true|false side=PERSON|PC
        // console.log("DemoGamePage.js: playGame()",choosed);

        setBtnPlayGameTrueDisabled(true);
        setBtnPlayGameFalseDisabled(true);
        setLastEstimate(choosed);
        await delay(1000);
        let randomTF = getRandomTF();
        setvideoSrc(randomTF ? videoTrue : videoFalse);
        setExpireTime(-1);
        exTime = -1;
        if(side==='PERSON'){
            setCurrentGamer("Your");
            if(choosed===randomTF) {++score1_; setScore1(score1_)}
            else                   {++score2_; setScore2(score2_)}    
        } else {
            setCurrentGamer("Rival");
            if(choosed===randomTF) {++score2_; setScore2(score2_)}
            else                   {++score1_; setScore1(score1_)}    
        }
        
        await delay(2000);
        setBtnPlayGameTrueDisabled(false);
        setBtnPlayGameFalseDisabled(false);

        // OYUN BİTMİŞSE
        if(score1_>1 || score2_>1){   
            setBtnWaitShow(false);
            setBtnPlayShow(false);
            setIsFinished(true);
            setIsStarted(false);
        } else {

            if(side==="PERSON"){
                setBtnWaitShow(true);
                setBtnPlayShow(false);
                await delay(10000);        
                playGame(getRandomTF(),"PC");
            } else {
                setBtnWaitShow(false);
                setBtnPlayShow(true);
                await delay(4000);
                setExpireTime(120);
                exTime = 120;
            }
        }
        


    }


    const prepareBtns = ()=>{
        if(score1_===2) return  <>
                                    <Alert className="w-100 mb-1 p-0" variant="success">You Won</Alert>
                                    <Button key='tryAgain1' onClick={refreshPage} size="sm" variant='success'><span style={{fontSize:'small' }}>Try Again</span></Button>
                                </>                                    
        if(score2_===2) return  <>
                                    <Alert className="w-100 mb-1 p-0" variant="danger">You lost..</Alert>
                                    <Button key='tryAgain2' onClick={refreshPage} size="sm" variant='success'><span style={{fontSize:'small' }}>Try Again</span></Button>
                                </>
        if(btnWaitShow) return <Alert className="w-100 mb-0 p-0" variant="warning">Waiting for the PC move.</Alert>
        if(btnStartShow)return <Button key='StartGame' onClick={()=>startGameClicked()} size="sm" variant='success'><span style={{fontSize:'small' }}>Start Game</span></Button>
        if(isExpired)   return <>
                                    <Alert className="w-100 mb-1 p-0" variant="danger">This game is expired, should be closed </Alert>                        
                                    <Button key='tryAgain3' onClick={refreshPage} size="sm" variant='success'><span style={{fontSize:'small' }}>Try Again</span></Button>
                                </>
        if(btnPlayShow){
            return  <>
                        <Alert className="w-100 mb-1 p-0" variant="warning">Make Your Choice</Alert>
                        <Button key='PlayGameTrue' onClick={()=>playGame(true)} disabled={btnPlayGameTrueDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'small', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./heads.png`} />{btnPlayGameTrueDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                        <Button key='PlayGameFalse' onClick={()=>playGame(false)} disabled={btnPlayGameFalseDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'small', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./tails.png`} />{btnPlayGameFalseDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                    </>
        }
    }

    return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
        <div className="d-flex flex-column text-center justify-content-center align-items-center w-100">
            {isExploding && <ConfettiExplosion />}
            {videoSrc
                ?   <div className="w-100">
                        <Alert variant="primary">{currentGamer} Estimate: {lastEstimate?'Heads':'Tails'}</Alert>
                        <video autoPlay muted playsInline onEnded={async()=>{
                            await delay(3000); 
                            setvideoSrc(null);
                                 if(score1_===2) {
                                    setIsExploding(true);
                                    await delay(7000);
                                    setIsExploding(false);
                                }
                            
                            }} style={{width:'100%', maxHeight:'100%', maxWidth:'550px'}}>
                            <source src={videoSrc} type="video/mp4" />
                        </video>
                    </div>
                :   <div className="w-100">   
                    <Card style={{width:'100%', marginTop:10, backgroundColor:'rgba(255, 222, 180, 0.8)', borderColor:'#787878'}}>
                        <Card.Header as="h5">Score</Card.Header>
                        <Card.Body>                                        
                            {isStarted && !isFinished && expireTime>0 
                                ? <div className="d-flex justify-content-around align-items-center glow">Expire Time: {expireTime}</div>
                                : (/*isFinished && !isStarted && expireTime<1*/ isExpired)
                                    ? <div className="d-flex justify-content-around align-items-center expired-text">This Game Expired</div>
                                    : null
                            }

                            <div className="d-flex justify-content-around align-items-center">
                                <span>You</span><span></span><span>PC</span>                                            
                            </div>
                            <div className="d-flex justify-content-around align-items-center">
                                <span className="score" ref={refScore1}>{score1}</span>
                                <span className="score-hyphen">-</span>
                                <span className="score" ref={refScore2}>{score2}</span>
                            </div>                                
                        </Card.Body>                                    
                    </Card>                                                              
                    
                    <Card style={{width:'100%', marginTop:10, backgroundColor:'rgba(255, 248, 180, 0.8)', borderColor:'#787878' }}>
                        <Card.Body>
                            <div className="d-flex justify-content-around align-items-center">
                                <span>Award: XXX</span>
                            </div>
                        </Card.Body> 
                    </Card>
                   
                    <div style={{height:160}}></div>
                    <div className="footer fixed-bottom d-flex flex-wrap align-items-center justify-content-around p-1" style={{minHeight:58, backgroundColor:'#f2f2f2'}}>
                        {prepareBtns()}
                    </div>

                    </div>
            }
        </div>
        {isExploding && 
            <div id="winDiv" style={{position:'absolute',top: '50%', left: '50%',zIndex:9999,transform:'translate(-50%,-50%)'}}><span style={{color:'red'}} className="score w150">YOU WIN</span></div>
        }
    </div>
    
    
  );
}

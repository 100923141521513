import { React, useState, useContext} from "react";
import { AppContext } from "../App";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { BsJustifyLeft } from "react-icons/bs";
export default function HelpCreateGame() {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  
  return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
      <div
        className="d-flex flex-column text-center justify-content-center align-items-center"
        style={{
          width:"90%",
          maxWidth: 600,
          marginTop: 50,
          padding: 25,
          border: "1px solid rgb(222, 226, 230)",
        }}
      >
          <div className="" style={{width:'100%'}}>
       
        <Button
          size="lg"
          style={{ borderRadius: 25, float: 'left' }}
          variant="info"
          onClick={() => { 
          navigate('/')
          }}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Back</span>
        </Button>
        
          <img className="logo-light" src="/logo.png" alt="logo" style={{float:'right'}}/>
        </div>
       
        <div style={{ marginTop: 25, textAlign:"left"}}>
               <p><span><b>How to Play Regular Game?</b></span></p>
               <p><span>1) You can join one of the open games in the "Challenge Games" section or create a new game by clicking the "Create New Challenge Game" button.</span></p>
               <p><span>2) After joining the game, When it's your turn, choose heads or tails.</span></p>
               <p><span>3) The first player to reach 2 points wins the game.</span></p>
               <p><span>4) Click the "claim award" button and enjoy.</span></p><br></br><br></br>
              
               <p><span>! Make sure you have enough BNB in your wallet for transaction fees (BNB for about 0.5$)</span></p><br></br><br></br>
               <p><span><b>The Rules of regular Game</b></span></p><br></br>
               <ul>
               <li>The player who creates the game has the first turn.</li>
               <li>Each player will have two minutes to play when it's their turn. If they fail to choose within this time, the game will be canceled with a penalty. (Please take a look at the exceptional cases.)</li>
               <li>The initial score is 0:0. Each player takes turns to make a heads/tails prediction. The player who guesses correctly earns 1 point. The player who does not guess correctly earns his opponent a point.</li>
               <li>The game will be canceled unless the first player fails to choose until the countdown ends. The amount they deposited for the game will be returned to the players without deductions.</li>
               <li>Unless the score is not 0-0 and any of the opponents fail to predict until the countdown ends, the game will be canceled with a penalty.</li>
               <li>The player who does not comply with the rule will be penalized with a 30% penalty. The deduction amount is made according to the following rates.</li>
               <li>25% will be transferred to the opposing player.</li>
               <li>5% will remain in the TAC marketing account.</li>
               </ul>

              </div>


      </div>
    </div>
  );
}

import { React, useState, useContext} from "react";
import QRCode from "react-qr-code";
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider ,getToken} from "firebase/app-check";
import { getFunctions, httpsCallable} from "firebase/functions";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { AppContext } from "../App";
import validator from "validator";
import { Button } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Stepper from "react-stepper-horizontal";
import Resizer from "react-image-file-resizer";
export default function BeSponsored() {
const { dispatch } = useContext(AppContext);

  const navigate = useNavigate();
  
  const steps = [
    { title: " Welcome " },
    { title: " Information " },
    { title: " Select Package " },
    { title: " Verify " },
    { title: " Payment " },

  ];
  const [activeStep, setStep] = useState(0);
  const [btnSubmitDisabled,setBtnSubmitDisabled] = useState("disabled");
  const [btnSubmitProcessing,setSubmitProcessing] = useState(false);
  
  const [btnBackButtonVisible,setBackButtonVisible] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [qrback, setBack] = useState('#FFFFFF');
  const [qrfore, setFore] = useState('#000000');
  const [qrsize, setSize] = useState(128);
  const [sponsoredEmail,setSponsoredEmail] = useState("");
  const [sponsoredTitle,setSponsoredTitle] = useState("");
  const [sponsoredDesc,setSponsoredDesc] = useState("");
  const [sponsoredLink,setSponsoredLink] = useState("");
  const [sponsoredTwitterUser,setSponsoredTwitterUser] = useState("");
  const [sponsoredCost,setSponsoredCost] = useState("");
  const [sponsoredAdminCode,setSponsoredAdminCode] = useState("");
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };
  const firebaseConfig = {
    apiKey: "AIzaSyBVD7__S8tFmYBHcA2R47-ONtxfMGLDzbs",
    authDomain: "taccoin.firebaseapp.com",
    projectId: "taccoin",
    storageBucket: "taccoin.appspot.com",
    messagingSenderId: "609150439089",
    appId: "1:609150439089:web:ac7b7b3b392a83c41c5c90",
    measurementId: "G-5YFNF35T96"
  };
  
  const app = initializeApp(firebaseConfig);
  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      250,
      250,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });


const functions = getFunctions(app);
const storage = getStorage(app, "gs://taccoin.appspot.com");
//connectFunctionsEmulator(functions, "127.0.0.1", 5001);


const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Ld3-q0pAAAAANj_Di8G-xmS4VEyFDWrFbN46Ex9'),
  isTokenAutoRefreshEnabled: true
});  

const callApi = async (formData) => {
  let appCheckTokenResponse;
  try {
      appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
  } catch (err) {
      // Handle any errors if the token was not retrieved.
      return;
  }
  const sForm = httpsCallable(functions, 'submitSponsoredDetails',
  { 'X-Firebase-AppCheck': appCheckTokenResponse.token });
      
  sForm(formData)
    .then((result) => {
      setWalletAddress(result.data);
      
    }).catch((error) => {
     
      const message = error.message;
      console.log("Error:",message);
      setStep(4);
      });
   
      setBackButtonVisible(false);
      setSponsoredEmail("");
      setSponsoredTitle("");
      setSponsoredDesc("");
      setSponsoredLink("");
      setSponsoredTwitterUser("");
      setSponsoredCost("");
      setSponsoredAdminCode("");
      setStep(4);

  // Handle response from your backend.
}; 

  const submitForm = async() => {
 //sil
    /*
    const formData=[
      {"title":JSON.stringify(sponsoredTitle),
      "desc":JSON.stringify(sponsoredDesc),
      "email":JSON.stringify(sponsoredEmail),
      "link":JSON.stringify(sponsoredLink),
      "image_link":JSON.stringify(""),
      "twitter_user":JSON.stringify(sponsoredTwitterUser),
      "admin_code":sponsoredAdminCode,
      "cost":sponsoredCost}
    ];

    const sForm = httpsCallable(functions, 'submitSponsoredDetails');
    
    sForm(formData)
      .then((result) => {
        setWalletAddress(result.data);
        
      }).catch((error)=>
    {
      console.log(error);
    });

      return;
  //sil
  */
    setSubmitProcessing(true);

    if(sponsoredTitle==="")
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Project name must be filled'}});
      setStep(1);
      setSubmitProcessing(false);
      return;
    }
    if(sponsoredDesc==="")
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Description must be filled'}});
      setStep(1);
      setSubmitProcessing(false);
      return;
    }
    if(sponsoredCost==="")
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Sponsored package must be selected'}});
      setStep(2);
      setSubmitProcessing(false);
      return;
    }
    if(selectedImage===null)
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Sponsored image must be selected'}});
      setStep(1);
      setSubmitProcessing(false);
      return;
    }
    if (!validator.isEmail(sponsoredEmail)) 
      {
        dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
        toast_show:true,   toast_message:'Please check your email address for communication'}});
        setStep(1);
        setSubmitProcessing(false);
        return;
      }
      if(selectedImage.type !=='image/png' && selectedImage.type !=='image/jpg' && selectedImage.type !=='image/jpeg')
      {
        dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
        toast_show:true,   toast_message:'Your image type must be jpg,png,jpeg'}});
        setStep(1);
        setSubmitProcessing(false);
        return;
      }
      let storageRef;
     
      let imageName='sponsored'+Date.now();
      storageRef = ref(storage, imageName+'.png');
      imageName=imageName+".png";
      const resizedImage = await resizeFile(selectedImage);
      
      const uploadTask = uploadBytesResumable(storageRef, resizedImage);
  uploadTask.on('state_changed', 
  (snapshot) => {
   
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
  }, 
  (error) => {
    if(uploadTask.cancel)
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Your image size must be less than 250K'}});
      setSubmitProcessing(false);
      setSelectedImage(null);
      setStep(1);
      return;
    }else{
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Processing error while your form submitting... Please try again.'}});
      setSubmitProcessing(false);
      return;
    }
  }, 
 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
      
      console.log("Download url",downloadURL);
      const formData=[
        {"title":sponsoredTitle,
        "desc":sponsoredDesc,
        "email":sponsoredEmail,
        "link":sponsoredLink,
        "image_link":downloadURL,
        "image_name":imageName,
        "twitter_user":sponsoredTwitterUser,
        "admin_code":sponsoredAdminCode,
        "cost":sponsoredCost,
        "parentID":0,
        "parentUID":0}
      ];
    callApi(formData);
     
    });
  }
);
      
      
     
  }

  const controlWallet = httpsCallable(functions, 'controlWallet');
  const controlForm = async() => {
    
    setStep(2);
  }
  
 
  return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
      <div
        className="d-flex flex-column text-center justify-content-center align-items-center"
        style={{
          width:"90%",
          maxWidth: 600,
          marginTop: 50,
          padding: 25,
          border: "1px solid rgb(222, 226, 230)",
        }}
      >
          <div className="" style={{width:'100%'}}>
        {btnBackButtonVisible ?
        <Button
          size="lg"
          style={{ borderRadius: 25, float: 'left' }}
          variant="info"
          onClick={() => { 
          activeStep<1 ? navigate('/') : setStep(activeStep-1);
          }}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Back</span>
        </Button>
        :null}
          <img className="logo-light" src="/logo.png" alt="logo" style={{float:'right'}}/>
        </div>
        <h3 style={{ marginTop: 20 }}>Be Sponsored</h3>
        <div>
          <Stepper  steps={steps} activeStep={activeStep} />
        </div>
        {(() => {
        switch (activeStep) {
          case 0:
            return <div style={{ marginTop: 25 }}>
               <p><span><b>Welcome to the create sponsored game on Toss a Coin App</b></span></p>
               <p><span>We are presenting you with a unique opportunity to increase the visibility of your brand and reach your target audience more effectively!<br></br><br></br> We propose that you consider sponsoring the "Toss a Coin" project, which offers a creative and fun approach to promoting your brand.
"Toss a Coin" provides an excellent opportunity to promote your brand while engaging with your target audience.<br></br><br></br> The project allows individuals who want to promote their brand to create a prize-winning game. Then, through games played by participants, your brand's name can be introduced to a wide audience.
           </span></p>   
               <Button
          size="lg"
          
          style={{ borderRadius: 25,marginTop:30 }}
          variant="info"
          onClick={() => { setStep(1);}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Start Now</span>
          </Button>
              
              </div>;

          case 1:
            return <div style={{ marginTop: 25 }}>
              <form>
              <p><span style={{color:"green"}}>Your Information</span></p>
               <span><b>Your business / project name (*)</b></span>
               <p><small>(*) Shows on game page</small></p>
              <input id="title" style={{ marginBottom: 25 }} type="text" onChange={(e)=>setSponsoredTitle(e.target.value)} value={sponsoredTitle} placeholder="Your business / project name" className="form-control" />
              <span><b>Description (*)</b></span>
              <p><small>(*) Shows on game page</small></p>
              <textarea id="desc" placeholder="Sponsor Description" className="form-control" onChange={(e)=>setSponsoredDesc(e.target.value)} value={sponsoredDesc} />
              <span><b>Sponsored Image (*)</b></span>
              <p><small>(*) Shows on game page</small></p>
              <input style={selectedImage ? {marginBottom:5} : {marginBottom:25}} type="file" className="form-control" onChange={handleImageChange} />
      {selectedImage && (
        <img style={{marginBottom:20, width:50,height:50,margin:"0 auto"}} className="form-control" src={URL.createObjectURL(selectedImage)} alt="Selected Sponsored Image" />
      )}
              
              <span><b>Your business link</b></span>
              <input id="link" style={{ marginBottom: 25 }} type="text" placeholder="https://" className="form-control" onChange={(e)=>setSponsoredLink(e.target.value)} value={sponsoredLink}/>
              <span><b>Your Twitter(X) Name</b></span>
              <input id="twittername" style={{ marginBottom: 25 }} type="text" placeholder="@yourXname" className="form-control" onChange={(e)=>setSponsoredTwitterUser(e.target.value)} value={sponsoredTwitterUser}/>
             
              <span><b>Referance Code</b></span>
              <input id="refcode" style={{ marginBottom: 25 }} type="text" placeholder="Referance Code" className="form-control" onChange={(e)=>setSponsoredAdminCode(e.target.value)} value={sponsoredAdminCode}/>
              <span><b>Communication Email (*)</b></span>
              <input id="email" style={{ marginBottom: 25 }} type="text" placeholder="Your Email Address" className="form-control" onChange={(e)=>setSponsoredEmail(e.target.value)} value={sponsoredEmail}/>
 
          <Button
          size="lg"
         
          style={{ borderRadius: 25,marginTop:30 }}
          variant="info"
          onClick={() => { controlForm();}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Continue</span>
          </Button>
                </form>
            </div>;
          case 2:
            return <div>
   <div style={{marginTop:15,marginBottom:15}}>
   <span style={{color:"green"}}> Select your package</span>
    </div>
   

      <div>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>RunTime</th>
          <th>Prize</th>
          <th>Fee</th>
          <th>Total</th>
          <th>Select</th>
          
        </tr>
      </thead>
      <tbody>
      <tr>
          <td>2 hours</td>
          <td>$20</td>
          <td>$10</td>
          <td>$30</td>
          <td> <Button style={{ borderRadius: 25 }} variant="warning" onClick={() => {setSponsoredCost("30")}} className=""><span style={{ color: "white", marginRight: 5 }}>Choose</span></Button></td>
        </tr>

        <tr>
          <td>6 hours</td>
          <td>$50</td>
          <td>$25</td>
          <td>$75</td>
          <td> <Button style={{ borderRadius: 25 }} variant="warning" onClick={() => {setSponsoredCost("75")}} className=""><span style={{ color: "white", marginRight: 5 }}>Choose</span></Button></td>
        </tr>
        <tr>
          <td>12 hours</td>
          <td>$100</td>
          <td>$50</td>
          <td>$150</td>
          <td> <Button style={{ borderRadius: 25 }} variant="warning" onClick={() => {setSponsoredCost("150")}} className=""><span style={{ color: "white", marginRight: 5 }}>Choose</span></Button></td>
       
        </tr>
        <tr>
          <td>24 hours</td>
          <td>$200</td>
          <td>$100</td>
          <td>$300</td>
          <td> <Button style={{ borderRadius: 25 }} variant="warning" onClick={() => {setSponsoredCost("300")}} className=""><span style={{ color: "white", marginRight: 5 }}>Choose</span></Button></td>
       
        </tr>
        <tr>
          <td>48 hours</td>
          <td>$350</td>
          <td>$175</td>
          <td>$525</td>
          <td> <Button style={{ borderRadius: 25 }} variant="warning" onClick={() => {setSponsoredCost("525")}} className=""><span style={{ color: "white", marginRight: 5 }}>Choose</span></Button></td>
       
        </tr>
        <tr>
          <td>72 hours</td>
          <td>$500</td>
          <td>$250</td>
          <td>$750</td>
          <td> <Button style={{ borderRadius: 25 }} variant="warning" onClick={() => {setSponsoredCost("750")}} className=""><span style={{ color: "white", marginRight: 5 }}>Choose</span></Button></td>
       
        </tr>
        <tr>
          <td>1 Week</td>
          <td>$1000</td>
          <td>$500</td>
          <td>$1500</td>
          <td> <Button style={{ borderRadius: 25 }} variant="warning" onClick={() => {setSponsoredCost("1500")}} className=""><span style={{ color: "white", marginRight: 5 }}>Choose</span></Button></td>
       
        </tr>

      </tbody>
    </Table>
</div>
<div>
       <Button
          size="lg"
        
          style={{ borderRadius: 25,marginTop:30 }}
          variant="info"
          onClick={() => { setStep(3);}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Continue</span>
        </Button>
        </div>
    </div>

          case 3:
            return <div>
               <div style={{marginTop:15,marginBottom:15}}>
               <span style={{color:"green"}}>Please check your information</span>
    </div>
    <Table striped hover>
      
      <tbody>
    
      <tr>
          <td align="left"><b>Your business / project name (*)</b></td>
          <td>{sponsoredTitle}</td>
      </tr>
      <tr>
          <td align="left"><b>Description (*)</b></td>
          <td>{sponsoredDesc!=="" ?sponsoredDesc : '-'}</td>
      </tr>
      <tr>
          <td align="left"><b>Your business link</b></td>
          <td>{sponsoredLink!=="" ? sponsoredLink : '-'}</td>
      </tr>
      <tr>
          <td align="left"><b>Your Twitter(X) Name</b></td>
          <td>{sponsoredTwitterUser!=="" ? sponsoredTwitterUser : '-'}</td>
      </tr>
      <tr>
          <td align="left"><b>Sponsor Image</b></td>
          <td>{selectedImage && (
        <img style={{marginBottom:20, width:50,height:50,margin:"0 auto"}} className="form-control" src={URL.createObjectURL(selectedImage)} alt="Selected Sponsored Image" />
      )}</td>
      </tr>
      <tr>
          <td align="left"><b>Ref Code</b></td>
          <td>{sponsoredAdminCode!=="" ? sponsoredAdminCode : '-'}</td>
      </tr>
      <tr>
          <td align="left"><b>Communication Email (*)</b></td>
          <td>{sponsoredEmail}</td>
      </tr>
      <tr>
          <td align="left"><b>Total Cost</b></td>
          <td>{sponsoredCost!=="" ? "$"+sponsoredCost : '-'}</td>
      </tr>
    
      </tbody>
    </Table>
   
    <div style={{marginTop:15}}>
               
               <input id="acceptrules" type="checkbox" onClick={()=>setBtnSubmitDisabled('')} />
                   
                     <span> I confirm the accuracy of the information provided above </span>
               </div>
        <div>
       <Button
          size="lg"
          disabled={btnSubmitDisabled || btnSubmitProcessing}
          style={{ borderRadius: 25,marginTop:30 }}
          variant="success"
          onClick={() => { submitForm();}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>{btnSubmitProcessing ? 'Processing...' : 'Submit'}</span>
        </Button>
        </div>
           </div>;
          case 4:
            return <div style={{ marginTop: 25 }}>
            {walletAddress!==null ? 
            <div>
               <p><span style={{color:"green"}}><b>Thank you. Your game information saved successfully.</b></span></p>
               <p><h4>Please send {sponsoredCost} USDT to BEP-20 address below.</h4></p> 
           <p><span style={{color:"red"}}>This Transaction will expire on 1 hour. Please don't send after that time.</span></p>
           <p><span style={{color:"red"}}>This address is for one-time use only. Please do not save</span></p>
          
           <p>
           <QRCode
                        title="Toss a Coin"
                        value={walletAddress}
                        bgColor={qrback}
                        fgColor={qrfore}
                        size={qrsize === '' ? 0 : qrsize}
                    />
            
            </p>
           <p><span>{walletAddress}</span></p>
           
           <p> <button style={{borderRadius:15}}
  onClick={() => {
  navigator.clipboard.writeText(walletAddress);
  dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
											toast_show:true,   toast_message:'Copied to clipboard',}})
        }}>
 Copy to clipboard
</button></p>
</div>
  :
  <div>
    <span>Please wait...</span>
    </div>
  }

{walletAddress!==null ? 
               <Button
          size="lg"
          
          style={{ borderRadius: 25,marginTop:30 }}
          variant="success"
          onClick={() => { navigate("/");}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Finish</span>
          </Button>
      :null}      
              </div>;

  default:
            return null;
        }
      })()}       


      </div>
    </div>
  );
}

import { React, useState, useEffect, useContext, useRef } from "react";
import { bscProviderUrl } from '../common/const';

import App, { AppContext } from "../App";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { addUser } from "../db";
import Stepper from "react-stepper-horizontal";
import { ethers } from 'ethers';
import CryptoJS from 'crypto-js';
export default function CreateWallet() {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  
  const steps = [
    { title: "Create password" },
    { title: "Secure wallet" },
    { title: "Confirm secret recovery phrase" },
    { title: "Finish" },

  ];
  const [activeStep, setStep] = useState(0);
  const [btnCreateWalletDisabled,setBtnCreateButtonDisabled] = useState("disabled");
  const [userPass,setUserPass] = useState(null);
  const [userWallet,setUserWallet]=useState(null);
  const [mnemonicArray,setMnemonicArray]=useState(null);
  const [selectedIndexes,setSelectedIndexes]=useState(null);
  const[elem, setElem]=useState("");
  const handleChangeElem = (event) => {
    setElem(event.value);
  }
  const controlForm = async() => {
    const password = document.getElementById('password').value;
    const confirmpassword = document.getElementById('confirmpassword').value;
    const acceptrules = document.getElementById('acceptrules').value;
    if(acceptrules!=='on')
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Please accept rules.'}});
      return;
    }
    if(password.length<8)
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Password must be at least 8 characters'}});
      return;
    }
    if(password!==confirmpassword)
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Passwords not match'}});
      return;
    }
    setUserPass(password);
    setStep(1);
  }

  const controlKey = async() => {

    function getInputValuesByClassName(className) {
      const inputElements = document.getElementsByClassName(className);
      const values = [];
    
      for (let i = 0; i < inputElements.length; i++) {
        values.push(inputElements[i].value);
      }
    
      return values;
    }

    function compareArrays(array1, array2) {
      if (array1.length !== array2.length) {
        return false;
      }
    
      const sortedArray1 = array1.slice().sort();
      const sortedArray2 = array2.slice().sort();
    
      for (let i = 0; i < sortedArray1.length; i++) {
        if (sortedArray1[i] !== sortedArray2[i]) {
          return false;
        }
      }
    
      return true;
    }
    
    if(!compareArrays(mnemonicArray, getInputValuesByClassName("controlInput")))
    {
      dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
      toast_show:true,   toast_message:'Please control your secret recovery phrase'}});
      return;
    } 
    
    const { v4: uuidv4 } = require('uuid');
    const uid = uuidv4();
    const key= uid + userPass + 'TossACoin';
    const hash = CryptoJS.AES.encrypt(userWallet.privateKey, key).toString();
    addUser("Wallet",uid,hash);
    dispatch({ type: "wallet_type", payload: "Wallet" });
    dispatch({ type: "isLogon", payload: true });
    return;
  }

  if(activeStep===1)
  {
     if(userWallet===null)
     {
      let wallet=ethers.Wallet.createRandom()
      setUserWallet(wallet);
      console.log('address:', wallet.address);
      console.log('mnemonic:', wallet.mnemonic.phrase);
      console.log('privateKey:', wallet.privateKey);
    
      setMnemonicArray(wallet.mnemonic.phrase.split(' '));
     }else
     {
    
      if(mnemonicArray===null)
      setMnemonicArray(userWallet.mnemonic.phrase.split(' '));
     }  
     if (selectedIndexes!==null) setSelectedIndexes(null);
  }
  if(activeStep===2)
  {
   
    function getRandomIndexes(count, min, max) {
      const randomNumbers = [];
      while (randomNumbers.length < count) {
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        if (!randomNumbers.includes(randomNumber)) {
          randomNumbers.push(randomNumber);
        }
      }
      return randomNumbers;
    }
    if (selectedIndexes===null) {
      setSelectedIndexes(getRandomIndexes(3, 0, 11));
      setElem("");
    }
   
  }
  
  const handleClearValues = (val) => {
    
  }
  return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
      <div
        className="d-flex flex-column text-center justify-content-center align-items-center"
        style={{
          maxWidth: 600,
          marginTop: 50,
          padding: 25,
          border: "1px solid rgb(222, 226, 230)",
        }}
      >
          <div className="" style={{width:'100%'}}>
        <Button
          size="lg"
          style={{ borderRadius: 25, float: 'left' }}
          variant="info"
          onClick={() => { 
          activeStep<1 ? navigate('/login') : setStep(activeStep-1);
          }}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Back</span>
        </Button>
          <img className="logo-light" src="/logo.png" alt="logo" style={{float:'right'}}/>
        </div>
        <h3 style={{ marginTop: 20 }}>Create Wallet</h3>
        <div>
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        {(() => {
        switch (activeStep) {
          case 0:
            return <div style={{ marginTop: 25 }}>
              <form>
              <p><span>This password will unlock your wallet only on this device. Toss a Coin can not recover this password.</span></p>
               <span><b>New password (8 characters min)</b></span>
              <input id="password" style={{ marginBottom: 25 }} type="password" placeholder="" className="form-control" />
              <span><b>Confirm password</b></span>
              <input id="confirmpassword" type="password" placeholder="" className="form-control" />
              <div style={{marginTop:15}}>
               
                <input id="acceptrules" type="checkbox" onClick={()=>setBtnCreateButtonDisabled('')} />
                    
                      <span> I understand that Toss a Coin cannot recover this password for me.</span>
                </div>

          <Button
          size="lg"
          disabled={btnCreateWalletDisabled}
          style={{ borderRadius: 25,marginTop:30 }}
          variant="info"
          onClick={() => { controlForm();}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Create Wallet</span>
        </Button>
                </form>
            </div>;
          case 1:
            return <div>
   <div style={{marginTop:15,marginBottom:15}}>
        Write down this 12-word Secret Recovery Phrase and save it in a place that you trust and only you can access.
    </div>
     
      {
      mnemonicArray!==null ?
      mnemonicArray.map((value, index) => (
        <input style={{borderRadius:15,border:'1px solid darkgray',margin:5,padding:5}}
          key={index}
          type="text"
          readOnly="readOnly"
          value={value}
          
        />
      ))
      
      :null
      }
      <div>
      <button style={{borderRadius:15}}
  onClick={() =>  navigator.clipboard.writeText(userWallet.mnemonic.phrase)}>
 Copy to clipboard
</button>
</div>
<div>
       <Button
          size="lg"
          disabled={btnCreateWalletDisabled}
          style={{ borderRadius: 25,marginTop:30 }}
          variant="info"
          onClick={() => { setStep(2);}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Continue</span>
        </Button>
        </div>
    </div>

          case 2:
            return <div>
               <div style={{marginTop:15,marginBottom:15}}>
        Write down this 12-word Secret Recovery Phrase and save it in a place that you trust and only you can access.
    </div>
 {
      selectedIndexes!==null ?
      mnemonicArray.map((valueArray, index) => (
        
        <input className="controlInput" style={{borderRadius:15,border:selectedIndexes.includes(index) ? '2px solid red':'1px solid darkgray',margin:5,padding:5}}
          key={index}
          type="text"
          value={selectedIndexes.includes(index) ? elem : valueArray}
          onChange={(e)=>handleChangeElem(e)}
          readOnly={selectedIndexes.includes(index) ? false : true}
        />
        
      ))
      :null

        }
        <div>
       <Button
          size="lg"
          disabled={btnCreateWalletDisabled}
          style={{ borderRadius: 25,marginTop:30 }}
          variant="info"
          onClick={() => { controlKey();}}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Finish</span>
        </Button>
        </div>
           </div>;
          default:
            return null;
        }
      })()}       


      </div>
    </div>
  );
}

import { React, useState, useContext} from "react";
import { AppContext } from "../App";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
export default function HelpCreateSponsoredGame() {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  
  return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
      <div
        className="d-flex flex-column text-center justify-content-center align-items-center"
        style={{
          width:"90%",
          maxWidth: 600,
          marginTop: 50,
          padding: 25,
          border: "1px solid rgb(222, 226, 230)",
        }}
      >
          <div className="" style={{width:'100%'}}>
        
        <Button
          size="lg"
          style={{ borderRadius: 25, float: 'left' }}
          variant="info"
          onClick={() => { 
          navigate('/')
          }}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Back</span>
        </Button>
       
          <img className="logo-light" src="/logo.png" alt="logo" style={{float:'right'}}/>
        </div>
       
        <div style={{ marginTop: 25 ,textAlign:"left"}}>
               <p><span><b>How to Play Sponsored Game?</b></span></p>
               <p><span>1) Click the "Request to Join Game" button to join the game for free. (only transaction fees will be paid.)</span></p>
               <p><span>2) When the countdown finishes, the decentralized contract will select two lucky players. (The election will be fair and transparent. You can check all the transactions on Bscscan)</span></p>
               <p><span>3) After the lottery, If you are one of the lucky players, You will have the right to join the game.</span></p>
               <p><span>4) After joining the game, When it's your turn, choose heads or tails.</span></p>
               <p><span>5) The first player to reach 2 points wins the game.</span></p>
               <p><span>6) Click the "claim award" button and enjoy.</span></p><br></br>
               <p><span>! Make sure you have enough BNB in your wallet for transaction fees (BNB for about 0.5$) </span></p>
               <br></br><br></br>
               <p><span><b>The Rules of Sponsored Game</b></span></p>
               <p>
                <ul>
                <li>The first turn belongs to the first person randomly selected by the contract among the applicants.</li>
                <li>Each player will have two minutes to play when it's their turn. If they fail to choose within this time, the game will be canceled with a penalty. (Please take a look at the exceptional cases.)</li>
                <li>The initial score is 0:0. Each player takes turns to make a heads/tails prediction. The player who guesses correctly earns 1 point. The player who does not guess correctly earns his opponent a point.</li>
                <li>If any of the opponents do not join the game, the game will be canceled. It is at the sponsor's discretion to reopen the game.</li>
                <li>The game will be canceled unless one of the opponents fails to choose until the countdown ends. It is at the sponsor's discretion to reopen the game.</li>
                <li>The player who does not comply with the rule will be penalized with a 30% penalty. The deduction amount is made according to the following rates.</li>
                <li>25% will be transferred to the opposing player.</li>
                <li>5% will remain in the TAC marketing account.</li>
                </ul>
                </p> 

              </div>


      </div>
    </div>
  );
}

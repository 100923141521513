import React, { useState, useEffect, useContext, useRef } from "react";
import ConfettiExplosion from 'confetti-explosion-react';
import {  Alert, Spinner, Button,Card, Placeholder, Badge, ListGroup} from "react-bootstrap";
import { initializeApp } from 'firebase/app';
import { getFirestore , collection ,query, where, getDocs } from "firebase/firestore";

import { ethers } from 'ethers';
import { AppContext, tac_contract } from "../App";
import { useParams, useNavigate  } from "react-router-dom";
import { convertToBigInt,convertHexToBigInt, delay, erc20ABI, objGame } from "../common/const";
import videoTrue from '../media/videos/true.mp4';
import videoFalse from '../media/videos/false.mp4';
import FlipDown from "../common/flipdown";
import { gsap } from "gsap";

function isPositiveNumber(str){ return /^\+?\d+$/.test(str); }
let create_game_provider = null;
let create_game_signer   = null;
let create_game_contract = null;
let sponsoredDataFetch = false;
let isApprove=null;
//test
let isClaimedTest=true;
//test
export default function GamePage() {
    
    let oldscore1 = null;
    let oldscore2 = null;

    const { id } = useParams();
    const {state, dispatch} = useContext(AppContext);
    const navigate = useNavigate(); 
    
    const refScore1 = useRef();
    const refScore2 = useRef();
    
    const [isExploding, setIsExploding] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [btnJoinGameDisabled, setBtnJoinGameDisabled]           = useState(false);
    const [sponsoredImage, setSponsoredImage]           = useState('');
    
    
    const [btnPlayGameTrueDisabled, setBtnPlayGameTrueDisabled]   = useState(false);
    const [btnPlayGameFalseDisabled, setBtnPlayGameFalseDisabled] = useState(false);
    const [btnCloseGameDisabled, setBtnCloseGameDisabled]         = useState(false);
   
    const [btnApproveDisabled, setBtnApproveDisabled]             = useState(false);
    const [btnClaimDisabled, setbtnClaimDisabled]                 = useState(false);
    //test
    const [btnClaimTestDisabled, setbtnClaimTestDisabled]          = useState(true);
    //test
    const [btnRequestJoinGameDisabled, setBtnRequestJoinGameDisabled] = useState(false);
    const [hasRequestedJoinGame, sethasRequestedJoinGame] = useState(null);
   
    const [sponsoredJSONData, setSponsoredJSONData] = useState(null);
    

    const [gameDetailsFetching, setGameDetailsFetching] = useState(false);
    
    const [expireTime, setExpireTime] = useState(0);

    const [videoSrc, setvideoSrc] = useState(null);
    const firebaseConfig = {
        apiKey: "AIzaSyBVD7__S8tFmYBHcA2R47-ONtxfMGLDzbs",
        authDomain: "taccoin.firebaseapp.com",
        projectId: "taccoin",
        storageBucket: "taccoin.appspot.com",
        messagingSenderId: "609150439089",
        appId: "1:609150439089:web:ac7b7b3b392a83c41c5c90",
        measurementId: "G-5YFNF35T96"
      };
      
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const getSponsoredDetails= async (gID)=>{
        const q = query(collection(db, "sponsored"), where("gameID", "==", Number(gID)));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setSponsoredJSONData(JSON.parse(doc.data().gameDetails));
         
          setSponsoredImage(`https://firebasestorage.googleapis.com/v0/b/taccoin.appspot.com/o/${doc.data().gameImage}?alt=media`);
          console.log(sponsoredImage);
        });
       
    }  
    const connectButtonOnClick = () => {
        dispatch({type:'modal_show_state', payload:true});
    }

    useEffect(() => {
        if(!isPositiveNumber(id)) navigate(`/`);
        // console.log("GamePage useEffect");
        // console.log("Old Score 1:",oldscore1);

        let interval = setInterval(async () => {await fetch_GetGame()}, 1000);
        

        //isSponsored
        if(state.game_selected) {
            sponsoredDataFetching(state.game_selected);
        }
        getSponsoredDetails(id);
      /*
        fetch(`https://firebasestorage.googleapis.com/v0/b/taccoin.appspot.com/o/sponsored%2F${id}.json?alt=media`)
  .then(response => {
    
    if (response.ok) {
     
      return response.json();
    } else {
      return '';
    }
    })
  .then(data => {
    
    setSponsoredJSONData(data);
  })
  .catch(error => {
    fetch(`./sponsored/_.json`)
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return '';
    }
  })
  .then(data => {
    setSponsoredJSONData(data);
  })
    // Hata durumunda istediğiniz işlemleri gerçekleştirin
  });
          */


      //  import(`../../public/sponsored/${id}.json`).then(res=>{setSponsoredJSONData(res.default)}).catch(_=>null);
     
        return () => { 
            // console.log("Oyundan Çıktık");
            oldscore1 = null;
            oldscore2 = null;
            clearInterval(interval);
            isApprove=null;
            //test
            isClaimedTest=true;
            //test
            sponsoredDataFetch = false;
            setvideoSrc(null);
            setExpireTime(0);
            setIsExpired(false);
            sethasRequestedJoinGame(null);
            setBtnRequestJoinGameDisabled(false);
            setbtnClaimDisabled(false);
            setBtnApproveDisabled(false);
            setBtnCloseGameDisabled(false);
            setBtnPlayGameFalseDisabled(false);
            setBtnPlayGameTrueDisabled(false);
            setBtnJoinGameDisabled(false);
            //test
            setbtnClaimTestDisabled(true);
            //test
        };
      }, []);


    useEffect(()=>{
        if((oldscore1>0 || oldscore2>0) ) setTimeout(()=>{gsap.to(refScore1.current, { rotation: "+=360" })},10000);
    },[oldscore1]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    useEffect(()=>{
        if(oldscore1>0 || oldscore2>0 ) setTimeout(()=>{gsap.to(refScore2.current, { rotation: "+=360" })},10000);
    },[oldscore2])

    const fetch_GetGame = async () => {
        if(!gameDetailsFetching){
            setGameDetailsFetching(true);
            if(tac_contract){
                let x    = await tac_contract.getGame(id);
                if(Number(convertHexToBigInt(x.gameId))===0) navigate(`/`); 

                let game = objGame(x);
                // console.log(game);

                if(oldscore1===null){
                    oldscore1=game.score1;
                    oldscore2=game.score2;
                }

                if(!game.isFinished){
                    if(oldscore1!==game.score1 || oldscore2!==game.score2){
                        if(game.lastPlayerAddress===game.address1){
                            if(game.score1>oldscore1){
                                if(game.lastEstimate)  setvideoSrc(videoTrue); 
                                else                   setvideoSrc(videoFalse);
                            } else {
                                if(game.lastEstimate)  setvideoSrc(videoFalse); 
                                else                   setvideoSrc(videoTrue);
                            }
                        } else {
                            if(game.score2>oldscore2){
                                if(game.lastEstimate)  setvideoSrc(videoTrue); 
                                else                   setvideoSrc(videoFalse);
                            } else {
                                if(game.lastEstimate)  setvideoSrc(videoFalse); 
                                else                   setvideoSrc(videoTrue);
                            }
                        }
                        
                        oldscore1 = game.score1;
                        oldscore2 = game.score2;
                    } 
                }

                if(game.address2!=='0x0000000000000000000000000000000000000000'){ //game.score1>0 || game.score2>0
                    let diff = game.expireTime - parseInt(new Date().getTime()/1000);
                    // console.log("diff",diff);
                    if(diff>0) setExpireTime(diff); 
                    if(diff<1) setIsExpired(true);
                  

                }

                dispatch({type:'game_state_update', payload:{game_selected:game,game_selected_id:game.gameId}});

                sponsoredDataFetching(game);
            }                
        }
    }

    const sponsoredDataFetching = async(game) =>{
        if(!sponsoredDataFetch){
            // console.log("işlemler başlasın...",tac_contract);
            if(tac_contract){
                sponsoredDataFetch = true;
                let r = await tac_contract.isRequestedGame(game.gameId);
                // console.log("r",r)
                sethasRequestedJoinGame(r);
                try{
                    if(game.isSponsored) new FlipDown(game.sponsoredStartTime, "sponsoredStartTimeFlipDown").start();                
                }catch(e){
                    // console.log(e.message);
                }
            }
        }
    }
  
    const joinGameClicked = async() => {
        // console.log("GamePage.js: joinGameClicked()");
        setBtnJoinGameDisabled(true);
      /*  
        let currentBalance = await getContractBalance(state.game_selected.token);
        if(state.game_selected.amount > currentBalance ){
            setBtnJoinGameDisabled(false);
            dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
                    toast_show:true,   toast_message:"Amount exceeds balance",}});
                        
        } else {
       */
            isApprove=await isApproved(state.game_selected.token);
            
            // console.log("isApproved",isApprove);
            if(isApprove===true){
                try{
                    let joinG = await tac_contract.joinGame(state.game_selected.gameId);
                    let res   = await joinG.wait();
        
                    setBtnJoinGameDisabled(false);
                }catch(e){
                    let error = JSON.parse(JSON.stringify(e));
                    // console.log('joinGame Error:', error);
                    dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
                                                    toast_show:true,   toast_message:error?.reason?.replace('execution reverted: ','')||e.message,}})
                    // console.log(e.message);
                    setBtnJoinGameDisabled(false);
                }
            } else 
                setBtnJoinGameDisabled(false);
        //} 

        

    }  


    const closeGameClicked = async() => {
        // console.log("GamePage.js: closeGameClicked()");
        setBtnCloseGameDisabled(true);
       
        try{
            let joinG = await tac_contract.closeGame(state.game_selected.gameId);
            let res   = await joinG.wait();

            setBtnCloseGameDisabled(false);
        }catch(e){
			let error = JSON.parse(JSON.stringify(e));
    		// console.log('joinGame Error:', error);
			dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
											toast_show:true,   toast_message:error?.reason?.replace('execution reverted: ','')||e.message,}})
			// console.log(e.message);
            setBtnCloseGameDisabled(false);

        }

    }

    const playGame = async(choosed) => { //choosed=true|false
        // console.log("GamePage.js: playGame()");
        setBtnPlayGameTrueDisabled(true);
        setBtnPlayGameFalseDisabled(true);

        try{
            let playG = state.game_selected.isSponsored ? await tac_contract.playSponsored(state.game_selected.gameId, choosed) 
                                                        : await tac_contract.play(state.game_selected.gameId, choosed);
            let res   = await playG.wait();
            // console.log(res);
            setBtnPlayGameTrueDisabled(false);
            setBtnPlayGameFalseDisabled(false);
        }catch(e){
			let error = JSON.parse(JSON.stringify(e));
    		// console.log('playGame Error:', error);
			dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
											toast_show:true,   toast_message:error?.reason?.replace('execution reverted: ','')||e.message,}})
			// console.log(e.message);
            setBtnPlayGameTrueDisabled(false);
            setBtnPlayGameFalseDisabled(false);
        }

    }

    const btnClaimClicked = async() => {
        // console.log("GamePage.js: btnClaimClicked()");
        setbtnClaimDisabled(true);
        try {
            let res = await tac_contract.claimAward(state.game_selected.gameId);
            await res.wait();

            setbtnClaimDisabled(false);            
        } catch (err) {
            let error = JSON.parse(JSON.stringify(err));
            dispatch({type:'toast',payload:{toast_bg:'danger',      toast_header:'Error',
                                                   toast_show:true, toast_message:error?.reason?.replace('execution reverted: ','')||err.message,}})
                   
            // console.log('Claim error:' , err.message);
            setbtnClaimDisabled(false)
        }

    }
//test
    const btnClaimTestClick = async() => {
        // console.log("GamePage.js: btnClaimTestClicked()");
        setbtnClaimTestDisabled(true);
        try {
            let res = await tac_contract.claimTestToken();
            await res.wait();
            isClaimedTest=true;
            setbtnClaimTestDisabled(false);            
        } catch (err) {
            let error = JSON.parse(JSON.stringify(err));
            dispatch({type:'toast',payload:{toast_bg:'danger',      toast_header:'Error',
                                                   toast_show:true, toast_message:error?.reason?.replace('execution reverted: ','')||err.message,}})
                   
            // console.log('Claim test error:' , err.message);
            setbtnClaimTestDisabled(false);
        }

    }
//test

    const btnApproveClick = async() => {
        setBtnApproveDisabled(true);
        try {
            let current_game_provider = await new ethers.providers.Web3Provider(window.ethereum);
			let current_game_signer   = await current_game_provider.getSigner();
			let current_game_contract = new ethers.Contract(state.game_selected.token,erc20ABI,current_game_signer);
            
          //  let sellContractTotalSupply = convertHexToBigInt(await current_game_contract.totalSupply());
          //  sellContractTotalSupply = ethers.utils.parseUnits(`${sellContractTotalSupply}`, state.game_selected.tokenDecimals);
          //  sellContractTotalSupply = convertHexToBigInt(sellContractTotalSupply._hex);
          let decimals = await current_game_contract.decimals();
          let approveBalance = await current_game_contract.balanceOf(state.wallet_address);
            if(approveBalance < state.game_selected.amount)
            {
                dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
                toast_show:true,   toast_message:"Amount exceeds balance" }}) 
                return;
            }
            
		
            let approveTx = await current_game_contract.approve(state.contract_address, ethers.utils.parseUnits(`${state.game_selected.amount}`, 0));
            await approveTx.wait();

            isApprove=null;
            setBtnApproveDisabled(false);
            
        } catch (err) {
            dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
                                                   toast_show:true,   toast_message:err.message }})
                   
            // console.log('Approve error:' , err.message);
            setBtnApproveDisabled(false)
        }
    }

    const isApproved = async (e) => {           
        try{
            create_game_provider = await new ethers.providers.Web3Provider(window.ethereum);
            create_game_signer   = await create_game_provider.getSigner();
            create_game_contract = new ethers.Contract(e,erc20ABI,create_game_signer);
            let allowance = convertToBigInt(await create_game_contract.allowance(state.wallet_address, state.contract_address));
            let balance = convertToBigInt(await create_game_contract.balanceOf(state.wallet_address));
            //test
            if(balance<=0 && state.game_selected.tokenSymbol==='TAC' )
            {
                setbtnClaimTestDisabled(false);
                isClaimedTest=false;
                return false;
            }
            //test
            return balance-allowance<=0 ? true : false;
           
        }catch(e){
            dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
                                            toast_show:true,   toast_message:'Error getting contract details or contract not found!',}})
            // console.log(e.message);
            return false;
        }
    }     
    
    const getContractBalance = async (e) => {           
        try{
            create_game_provider = await new ethers.providers.Web3Provider(window.ethereum);
            create_game_signer   = await create_game_provider.getSigner();
            create_game_contract = new ethers.Contract(e,erc20ABI,create_game_signer);
            let balance = await create_game_contract.balanceOf(state.wallet_address);
            balance=Number(convertHexToBigInt(balance)); 
            return balance;
        }catch(e){
            dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
                                            toast_show:true,   toast_message:'Error getting contract details or contract not found!',}})
            // console.log(e.message);
            return 0;
        }
    }  
         
    const requestJoinGame = async (gameID) => {           
        setBtnRequestJoinGameDisabled(true);
        try {
            let res = await tac_contract.requestJoinGame(gameID);
            await res.wait();

            sethasRequestedJoinGame(true);
            setBtnRequestJoinGameDisabled(false);            
        } catch (err) {
            let error = JSON.parse(JSON.stringify(err));
            dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
                                            toast_show:true,   toast_message:error?.reason?.replace('execution reverted: ','')||err.message,}})
                    
            // console.log('requestJoinGame error:' , err.message);
            setBtnRequestJoinGameDisabled(false)
        }

    }
     

    const prepareBtns = ()=>{
       
        let btns = [];
       
        if(state.game_selected.isSponsored){
            if(hasRequestedJoinGame===null){
                return  <Spinner size="sm" animation="grow" />    
            }
            if(state.game_selected.address1==="0x0000000000000000000000000000000000000000"){
                if(hasRequestedJoinGame===false){
                    if(state.wallet_state==='NOT_CONNECTED')
                    return <Button key='connectWallet' onClick={connectButtonOnClick} size="sm" style={{marginRight:15}} variant='primary'><span style={{fontSize:'small', }}>Connect Wallet to Join Game</span></Button>
                    else
                    return <Button onClick={()=>requestJoinGame(state.game_selected.gameId)} disabled={btnRequestJoinGameDisabled}  size="sm" style={{marginRight:15}} variant='success'><span style={{fontSize:'small'}}>Request to Join Game (free) {btnRequestJoinGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                } else {
                    return <Alert className="w-100 mb-0 p-0" variant="info">You requested to join this game. Wait for start time</Alert>
                }
            }            
        }

        //sayfayı açan seyirci ise
        if(state.game_selected.address2.toLowerCase()!=="0x0000000000000000000000000000000000000000" &&
        ![state.game_selected.address1.toLowerCase(), state.game_selected.address2.toLowerCase()].includes(state.wallet_address.toLowerCase())){
            if(state.game_selected.isFinished || (state.game_selected.score1===2 || state.game_selected.score2===2) ){
                if(state.game_selected.score1===2) 
                    return <Alert className="w-100 mb-0 p-0" variant="info">Player 1 Won, Earned Award</Alert>
                if(state.game_selected.score2===2)
                    return <Alert className="w-100 mb-0 p-0" variant="info">Player 2 Won, Earned Award</Alert>
                if(state.game_selected.score1<2 && state.game_selected.score1>state.game_selected.score2)
                    return <Alert className="w-100 mb-0 p-0" variant="info">Player 2 left game... Player 1 earned other player penalty</Alert>
                if(state.game_selected.score2<2 && state.game_selected.score2>state.game_selected.score1)
                    return <Alert className="w-100 mb-0 p-0" variant="info">Player 1 left game... Player 2 earned other player penalty</Alert>
                if(state.game_selected.score2<2 && state.game_selected.score1<state.game_selected.score2)
                    return <Alert className="w-100 mb-0 p-0" variant="info">Player 1 left game... Player 1 paid penalty to other player</Alert>
                if(state.game_selected.score1<2 && state.game_selected.score2<state.game_selected.score1)
                    return <Alert className="w-100 mb-0 p-0" variant="info">Player 2 left game... Player 2 paid penalty to other player</Alert>                
                if(state.game_selected.score1<2 && state.game_selected.score1===state.game_selected.score2){
                    if(state.game_selected.isSponsored)
                        return <Alert className="w-100 mb-0 p-0" variant="info">This game cancelled</Alert>
                    else
                        return <Alert className="w-100 mb-0 p-0" variant="info">This game draw, amounts has been refunded </Alert>
                }
                if(state.game_selected.score2<2 && state.game_selected.score2===state.game_selected.score1){
                    if(state.game_selected.isSponsored)
                        return <Alert className="w-100 mb-0 p-0" variant="info">This game cancelled</Alert>
                    else
                        return <Alert className="w-100 mb-0 p-0" variant="info">This game draw, amounts has been refunded </Alert>
                }


            }
            return ; 
        }


        if(state.game_selected.isFinished){
            if(state.game_selected.score1<2 && state.game_selected.score2<2 && state.game_selected.lastPlayerAddress.toLowerCase()!=='0x0000000000000000000000000000000000000000'){
                if(state.game_selected.lastPlayerAddress.toLowerCase()!==state.wallet_address.toLowerCase())
                     return <Alert className="w-100 mb-0 p-0" variant="danger">You left game... You paid penalty to other player</Alert>
                else return <Alert className="w-100 mb-0 p-0" variant="success">Opponent left game... You earned other player penalty</Alert>
            }
                

            if(state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase()){
                if(state.game_selected.score1===2) 
                    return <Alert className="w-100 mb-0 p-0" variant="success">You Won, Earned Award</Alert>
                if(state.game_selected.score2===2) 
                    return <Alert className="w-100 mb-0 p-0" variant="danger">You lost.. Award paid to opponent </Alert>                
                if(state.game_selected.score1<2 && state.game_selected.score1===state.game_selected.score2){
                    if(state.game_selected.isSponsored)
                        return <Alert className="w-100 mb-0 p-0" variant="info">This game cancelled</Alert>
                    else
                        return <Alert className="w-100 mb-0 p-0" variant="success">This game draw, Your amount has been refunded </Alert>
                }
                    
            }
            if(state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()){
                if(state.game_selected.score2===2) 
                    return <Alert className="w-100 mb-0 p-0" variant="success">You Won, Earned Award</Alert>
                if(state.game_selected.score1===2)
                    return <Alert className="w-100 mb-0 p-0" variant="danger">You lost.. Award paid to opponent</Alert>
                if(state.game_selected.score2<2 && state.game_selected.score2===state.game_selected.score1){
                    if(state.game_selected.isSponsored)
                        return <Alert className="w-100 mb-0 p-0" variant="info">This game cancelled</Alert>
                    else
                        return <Alert className="w-100 mb-0 p-0" variant="success">This game draw, Your amount has been refunded </Alert>
                }
            }

        }

        //is expired
        if(state.game_selected.isExpired===true || (isExpired && state.game_selected.score1<2 && state.game_selected.score2<2)){
            if(!state.game_selected.isSponsored)                
                return  <>
                            <Alert className="w-100 mb-1 p-0" variant="danger">This game is expired, should be close </Alert>
                            <Button disabled={btnCloseGameDisabled} onClick={closeGameClicked} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'small', }}>Close Game {btnCloseGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                        </>
        }
         //is claimed test coin
         if(state.game_selected.address2.toLowerCase()==="0x0000000000000000000000000000000000000000" && 
         state.game_selected.address1.toLowerCase()!==state.wallet_address.toLowerCase() && state.game_selected.tokenSymbol==='TAC' && isClaimedTest===false ){ 
        
         return  <>
                      {!btnClaimTestDisabled && <Alert className="w-100 mb-1 p-0" variant="warning">You can one time claim TAC (test) token before you can participate in the game.</Alert>}
                      <Button key='ClaimTest' disabled={btnClaimTestDisabled} onClick={btnClaimTestClick} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'small', }}>Claim  {state.game_selected.tokenSymbol} {btnClaimTestDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                  </>
      }
        //is claimed test coin
        //is approved
        if(state.game_selected.address2.toLowerCase()==="0x0000000000000000000000000000000000000000" && 
           state.game_selected.address1.toLowerCase()!==state.wallet_address.toLowerCase() && isApprove===false ){ 
          
           return  <>
                        {!btnApproveDisabled && <Alert className="w-100 mb-1 p-0" variant="warning">You must approve before you can participate in the game.</Alert>}
                        <Button key='Approve' disabled={btnApproveDisabled} onClick={btnApproveClick} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'small', }}>Approve  {state.game_selected.tokenSymbol} {btnApproveDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                    </>
        }

       

        //btn join
        if(state.game_selected.address1.toLowerCase()!==state.wallet_address.toLowerCase() && 
           state.game_selected.address2.toLowerCase()==="0x0000000000000000000000000000000000000000"){
            if(state.wallet_state==='NOT_CONNECTED')
            btns.push(<Button key='connectWallet' onClick={connectButtonOnClick} size="sm" style={{marginRight:15}} variant='primary'><span style={{fontSize:'small', }}>Connect Wallet to Join Game</span></Button>);
            else
            btns.push(<Button key='JoinGame' onClick={joinGameClicked} disabled={btnJoinGameDisabled} size="sm" style={{marginRight:15}} variant='primary'><span style={{fontSize:'small', }}>Join Game ({(state.game_selected.amount/10**state.game_selected.tokenDecimals)*1} {state.game_selected.tokenSymbol}) {btnJoinGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
            
            return btns.length ? <>{btns}</> : null
        
        }

        //btn isClaimed
        if(state.game_selected.isClaimed===false){
            if     (state.game_selected.score1===2 && state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase() )
                btns.push(<Button disabled={btnClaimDisabled} onClick={btnClaimClicked}  key="Claimed-1" size="sm" style={{marginRight:15}} variant='success'><span style={{fontSize:'small', }}>Claim Award {btnClaimDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
            else if(state.game_selected.score2===2 && state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()) 
                btns.push(<Button disabled={btnClaimDisabled} onClick={btnClaimClicked}  key="Claimed-2" size="sm" style={{marginRight:15}} variant='success'><span style={{fontSize:'small', }}>Claim Award {btnClaimDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);

            if(btns.length>0) return <>{btns}</>
            else if((state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase() || 
                    state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()) &&
                    (state.game_selected.score1===2 || state.game_selected.score2===2)  )
                return <Alert className="w-100 mb-0 p-0" variant="danger">You lost.. Award paid to opponent</Alert>
 
                                    
        }

        

        //btn Play
        if(state.game_selected.address1.toLowerCase()!=="0x0000000000000000000000000000000000000000" && 
           state.game_selected.address2.toLowerCase()!=="0x0000000000000000000000000000000000000000" &&
           state.game_selected.score1<2 && state.game_selected.score2<2){
            if(state.game_selected.lastPlayerAddress==="0x0000000000000000000000000000000000000000" && 
               state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase()) {   
               btns.push(<Alert className="w-100 mb-1 p-0" variant="warning">Make Your Choice</Alert>);
               btns.push(<Button key='PlayGameTrue' onClick={()=>playGame(true)} disabled={btnPlayGameTrueDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'small', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./heads.png`} />{btnPlayGameTrueDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
               btns.push(<Button key='PlayGameFalse' onClick={()=>playGame(false)} disabled={btnPlayGameFalseDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'small', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./tails.png`} />{btnPlayGameFalseDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
            } else {
                if(state.game_selected.lastPlayerAddress.toLowerCase()!==state.wallet_address.toLowerCase() &&
                   state.game_selected.lastPlayerAddress.toLowerCase()!=="0x0000000000000000000000000000000000000000"){
                    btns.push(<Alert className="w-100 mb-1 p-0" variant="warning">Make Your Choice</Alert>);
                    btns.push(<Button key='PlayGameTrue'  onClick={()=>playGame(true)}  disabled={btnPlayGameTrueDisabled}  size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'small', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./heads.png`} />{btnPlayGameTrueDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
                    btns.push(<Button key='PlayGameFalse' onClick={()=>playGame(false)} disabled={btnPlayGameFalseDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'small', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./tails.png`} />{btnPlayGameFalseDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
                } else {
                    if(expireTime<=1 && !state.game_selected.isSponsored) btns.push(<Button disabled={btnCloseGameDisabled} onClick={closeGameClicked} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'small', }}>Close Game {btnCloseGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>); 
                    else return <Alert className="w-100 mb-0 p-0" variant="warning">Waiting for the opponent's move.</Alert>
                }
            }
            
        }    
        
        //Beklerken...
        if( state.game_selected.address2.toLowerCase()==="0x0000000000000000000000000000000000000000" && 
            state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase())
            return  <>
                        <Alert className="w-100 mb-1 p-0" variant="success">Waiting for opponent</Alert>
                        <Button disabled={btnCloseGameDisabled} onClick={closeGameClicked} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'small' }}>Close Game {btnCloseGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                    </>
        
        
        
        
        return btns.length ? <>{btns}</> : null
    }

    const prepareBtnsESKI20072022 = ()=>{
        let btns = [];
        

        // 
        if(state.game_selected.isSponsored){
            if(hasRequestedJoinGame===null){
                return  <Card style={{width:'100%', marginBottom:50, marginTop:10, borderColor:'#787878'}}>
                            <Card.Header as="h5">Actions</Card.Header>
                            <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                                <Spinner size="sm" animation="grow" />
                            </Card.Body>
                        </Card>    
            }
            if(state.game_selected.address1==="0x0000000000000000000000000000000000000000"){
                if(hasRequestedJoinGame===false){
                    return  <Card style={{width:'100%', marginBottom:50, marginTop:10, borderColor:'#787878'}}>
                    <Card.Header as="h5">Actions</Card.Header>
                    <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                    
                    <Button onClick={()=>requestJoinGame(state.game_selected.gameId)}  disabled={btnRequestJoinGameDisabled}  size="sm" style={{marginRight:15}} variant='success'><span style={{fontSize:'medium', }}>Request to Join Game (free) {btnRequestJoinGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                    
                    </Card.Body>
                </Card>
                } else {
                    return <Alert className="w-100 mt-3" variant="info">You requested to join this game. Wait for start time </Alert>
                }
            }            
        }

        //sayfayı açan seyirci ise
        if(state.game_selected.address2.toLowerCase()!=="0x0000000000000000000000000000000000000000" &&
        ![state.game_selected.address1.toLowerCase(), state.game_selected.address2.toLowerCase()].includes(state.wallet_address.toLowerCase())){
            if(state.game_selected.isFinished || (state.game_selected.score1===2 || state.game_selected.score2===2) ){
                if(state.game_selected.score1===2) 
                    return <Alert className="w-100 mt-3" variant="info">Player 1 Won, Earned {((state.game_selected.amount*(state.game_selected.isSponsored?1:2))/10**state.game_selected.tokenDecimals).toFixed(2)} {state.game_selected.tokenSymbol}</Alert>
                if(state.game_selected.score2===2)
                    return <Alert className="w-100 mt-3" variant="info">Player 2 Won, Earned {((state.game_selected.amount*(state.game_selected.isSponsored?1:2))/10**state.game_selected.tokenDecimals).toFixed(2)} {state.game_selected.tokenSymbol} </Alert>
                if(state.game_selected.score1<2 && state.game_selected.score1>state.game_selected.score2)
                    return <Alert className="w-100 mt-3" variant="info">Player 2 left game... Player 1 earned other player penalty</Alert>
                if(state.game_selected.score2<2 && state.game_selected.score2>state.game_selected.score1)
                    return <Alert className="w-100 mt-3" variant="info">Player 1 left game... Player 2 earned other player penalty</Alert>
                if(state.game_selected.score2<2 && state.game_selected.score1<state.game_selected.score2)
                    return <Alert className="w-100 mt-3" variant="info">Player 1 left game... Player 1 paid penalty to other player</Alert>
                if(state.game_selected.score1<2 && state.game_selected.score2<state.game_selected.score1)
                    return <Alert className="w-100 mt-3" variant="info">Player 2 left game... Player 2 paid penalty to other player</Alert>
                if(state.game_selected.score1<2 && state.game_selected.score1===state.game_selected.score2)
                    return <Alert className="w-100 mt-3" variant="info">This game draw, amounts has been refunded </Alert>
                if(state.game_selected.score2<2 && state.game_selected.score2===state.game_selected.score1)
                    return <Alert className="w-100 mt-3" variant="info">This game draw, amounts has been refunded </Alert>

            }
            return ; 
        }


        if(state.game_selected.isFinished){
            if(state.game_selected.score1<2 && state.game_selected.score2<2 && state.game_selected.lastPlayerAddress.toLowerCase()!=='0x0000000000000000000000000000000000000000'){
                if(state.game_selected.lastPlayerAddress.toLowerCase()!==state.wallet_address.toLowerCase())
                     return <Alert className="w-100 mt-3" variant="danger">You left game... You paid penalty to other player</Alert>
                else return <Alert className="w-100 mt-3" variant="success">Opponent left game... You earned other player penalty</Alert>
            }
                

            if(state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase()){
                if(state.game_selected.score1===2) 
                    return <Alert className="w-100 mt-3" variant="success">You Won, Earned Award</Alert>
                if(state.game_selected.score2===2) 
                    return <Alert className="w-100 mt-3" variant="danger">You lost.. Award paid to opponent </Alert>                
                if(state.game_selected.score1<2 && state.game_selected.score1===state.game_selected.score2)
                    return <Alert className="w-100 mt-3" variant="success">This game draw, Your amount has been refunded </Alert>
                    
            }
            if(state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()){
                if(state.game_selected.score2===2) 
                    return <Alert className="w-100  mt-3" variant="success">You Won, Earned Award</Alert>
                if(state.game_selected.score1===2)
                    return <Alert className="w-100  mt-3" variant="danger">You lost.. Award paid to opponent</Alert>
                if(state.game_selected.score2<2 && state.game_selected.score2===state.game_selected.score1)
                    return <Alert className="w-100  mt-3" variant="success">This game draw, Your amount has been refunded </Alert>
            }

        }


        //is expired
        if(state.game_selected.isExpired===true || isExpired){
            return <Card style={{width:'100%', marginBottom:50, marginTop:10, borderColor:'#787878'}}>
                        <Card.Header as="h5">Actions</Card.Header>
                        <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                            <Alert className="w-100" variant="danger">This game is expired, should be close </Alert>
                            <Button disabled={btnCloseGameDisabled} onClick={closeGameClicked} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'medium', }}>Close Game {btnCloseGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                        </Card.Body>
                    </Card>
        }
       
        //is approved
        if(state.game_selected.address2.toLowerCase()==="0x0000000000000000000000000000000000000000" && 
           state.game_selected.address1.toLowerCase()!==state.wallet_address.toLowerCase() && 
           isApprove===false ){ 
               
            return  <Card style={{width:'100%', marginBottom:50, marginTop:10, borderColor:'#787878'}}>
                            <Card.Header as="h5">Actions</Card.Header>
                            <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                            {!btnApproveDisabled && <Alert className="w-100" variant="warning">You must approve before you can participate in the game.</Alert>}
                            <Button key='Approve' disabled={btnApproveDisabled} onClick={btnApproveClick} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'medium', }}>Approve  {state.game_selected.tokenSymbol} {btnApproveDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                            </Card.Body>
                        </Card>
        
        }
        

        //btn join
        if(state.game_selected.address1.toLowerCase()!==state.wallet_address.toLowerCase() && 
           state.game_selected.address2.toLowerCase()==="0x0000000000000000000000000000000000000000"){
            btns.push(<Button key='JoinGame' onClick={joinGameClicked} disabled={btnJoinGameDisabled} size="sm" style={{marginRight:15}} variant='primary'><span style={{fontSize:'medium', }}>Join Game ({(state.game_selected.amount/10**state.game_selected.tokenDecimals)*1} {state.game_selected.tokenSymbol}) {btnJoinGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
            return btns.length ? <Card style={{width:'100%', marginBottom:50, marginTop:10, borderColor:'#787878'}}>
                                    <Card.Header as="h5" >Actions</Card.Header>
                                    <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                                        {btns}
                                    </Card.Body>
                                 </Card> : null
        }

        //btn isClaimed
        if(state.game_selected.isClaimed===false){
            if     (state.game_selected.score1===2 && state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase() )
                btns.push(<Button disabled={btnClaimDisabled} onClick={btnClaimClicked}  key="Claimed-1" size="sm" style={{marginRight:15}} variant='success'><span style={{fontSize:'medium', }}>Claim Award {btnClaimDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
            else if(state.game_selected.score2===2 && state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()) 
                btns.push(<Button disabled={btnClaimDisabled} onClick={btnClaimClicked}  key="Claimed-2" size="sm" style={{marginRight:15}} variant='success'><span style={{fontSize:'medium', }}>Claim Award {btnClaimDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);

            if(btns.length>0) return <Card style={{width:'100%', marginBottom:50, marginTop:10, borderColor:'#787878'}}>
                                        <Card.Header as="h5" >Actions</Card.Header>
                                        <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                                            {btns}
                                        </Card.Body>
                                    </Card>
            else if((state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase() || 
                    state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()) &&
                    (state.game_selected.score1===2 || state.game_selected.score2===2)  )
                return <Alert className="w-100" variant="danger">You lost.. Award paid to opponent</Alert>
 
                                    
        }

        

        //btn Play
        if(state.game_selected.address1.toLowerCase()!=="0x0000000000000000000000000000000000000000" && 
           state.game_selected.address2.toLowerCase()!=="0x0000000000000000000000000000000000000000" &&
           state.game_selected.score1<2 && state.game_selected.score2<2){
            if(state.game_selected.lastPlayerAddress==="0x0000000000000000000000000000000000000000" && 
               state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase()) {   
               btns.push(<Alert className="w-100" variant="warning">Make Your Choice</Alert>);
               btns.push(<Button key='PlayGameTrue' onClick={()=>playGame(true)} disabled={btnPlayGameTrueDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'medium', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./heads.png`} />{btnPlayGameTrueDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
               btns.push(<Button key='PlayGameFalse' onClick={()=>playGame(false)} disabled={btnPlayGameFalseDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'medium', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./tails.png`} />{btnPlayGameFalseDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
            } else {
                if(state.game_selected.lastPlayerAddress.toLowerCase()!==state.wallet_address.toLowerCase() &&
                   state.game_selected.lastPlayerAddress.toLowerCase()!=="0x0000000000000000000000000000000000000000"){
                    btns.push(<Alert className="w-100" variant="warning">Make Your Choice</Alert>);
                    btns.push(<Button key='PlayGameTrue'  onClick={()=>playGame(true)}  disabled={btnPlayGameTrueDisabled}  size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'medium', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./heads.png`} />{btnPlayGameTrueDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
                    btns.push(<Button key='PlayGameFalse' onClick={()=>playGame(false)} disabled={btnPlayGameFalseDisabled} size="sm" style={{marginRight:15}} variant='link'><span style={{fontSize:'medium', }}><img style={{maxWidth:'100%', cursor:'pointer' }} src={`./tails.png`} />{btnPlayGameFalseDisabled && <Spinner size="sm" animation="grow" />}</span></Button>);
                } else {
                    if(expireTime<=1) btns.push(<Button disabled={btnCloseGameDisabled} onClick={closeGameClicked} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'medium', }}>Close Game {btnCloseGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>); 
                    else return <Alert className="w-100 mt-3" variant="warning">Waiting for the opponent's move.</Alert>
                }
            }
            
        }    
        
        //Beklerken...
        if( state.game_selected.address2.toLowerCase()==="0x0000000000000000000000000000000000000000" && 
            state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase())
            return <div>
                        <Alert className="w-100 mt-3" variant="success">Waiting for opponent</Alert>
                        <Button disabled={btnCloseGameDisabled} onClick={closeGameClicked} size="sm" style={{marginRight:15}} variant='warning'><span style={{fontSize:'medium', }}>Close Game {btnCloseGameDisabled && <Spinner size="sm" animation="grow" />}</span></Button>
                    </div>
        
        return btns.length ? <Card style={{width:'100%', marginBottom:50, marginTop:10, borderColor:'#787878'}}>
                                <Card.Header as="h5" >Actions</Card.Header>
                                <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
                                    {btns}
                                </Card.Body>
                             </Card> : null
    }


    const msjInfo=()=>{
        if(hasRequestedJoinGame && state.game_selected.address1!=="0x0000000000000000000000000000000000000000" ){
            return [state.game_selected.address1.toLowerCase(),state.game_selected.address2.toLowerCase()].includes(state.wallet_address.toLowerCase())
            ? <Alert className="w-100" variant="primary">You have qualified to enter this game 🎉</Alert>
            : <Alert className="w-100" variant="info">You did not qualify to enter this game </Alert>
        }
    }

    return (
        
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
        {!tac_contract 
            ? <Alert className="w-100" variant="danger"> Wallet not connected!</Alert>
            : !state.game_selected
                ?   <Placeholder as={Card.Title} animation="glow" style={{width:'90%', marginBottom:50}} >
                        <Placeholder xs={12} /><Placeholder xs={12} /><Placeholder xs={9} /><Placeholder xs={12} />
                        <Placeholder xs={8} /><Placeholder xs={12} /><Placeholder xs={12} />
                    </Placeholder>     
                :   <div className="d-flex flex-column text-center justify-content-center align-items-center w-100">
                        {isExploding && <ConfettiExplosion />}
                        {videoSrc
                            ?   <div className="w-100">
                                    <Alert variant="primary">Estimate: {state.game_selected.lastEstimate?'Heads':'Tails'}</Alert>
                                    <video autoPlay muted playsInline onEnded={async()=>{
                                        await delay(3000);
                                        setvideoSrc(null);
                                        if(state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()){
                                            if(state.game_selected.score2===2) {
                                                setIsExploding(true);
                                                await delay(7000);
                                                setIsExploding(false);
                                            }
                                        }
                                        if(state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase()){
                                            if(state.game_selected.score1===2) {
                                                setIsExploding(true);
                                                await delay(7000);
                                                setIsExploding(false);
                                            }
                                        }  
                                         }} style={{width:'100%', maxHeight:'100%', maxWidth:'550px'}}>
                                        <source src={videoSrc} type="video/mp4" />
                                    </video>
                                </div>
                            :   <div className="w-100">   
                                {(!state.game_selected.isSponsored || 
                                 (state.game_selected.isSponsored&&state.game_selected.address2!=="0x0000000000000000000000000000000000000000" ) ) &&
                                <Card style={{width:'100%', marginTop:10, backgroundColor:'rgba(255, 222, 180, 0.8)', borderColor:'#787878'}}>
                                    <Card.Header as="h5">Score</Card.Header>
                                    <Card.Body>                                        
                                        {
                                        state.game_selected.address2!=="0x0000000000000000000000000000000000000000"
                                            ? expireTime>1 ? <div className="d-flex justify-content-around align-items-center glow">Expire Time: {expireTime}</div>
                                                           : (!state.game_selected.isFinished && state.game_selected.score1<2 && state.game_selected.score2<2) 
                                                                ? <div className="d-flex justify-content-around align-items-center expired-text">This Game Expired</div>
                                                                : null
                                            :null}

                                        <div className="d-flex justify-content-around align-items-center">
                                            <span className="w150">{state.game_selected.address1.toLowerCase()===state.wallet_address.toLowerCase()
                                                        ?   'You'
                                                        :   (state.game_selected.isSponsored && state.game_selected.address1.toLowerCase()==="0x0000000000000000000000000000000000000000" )
                                                                ?   'Waiting for random'
                                                                :   'Opponent'
                                                  }</span>
                                            <span className="w150"></span>
                                            <span className="w150">{state.game_selected.address2.toLowerCase()===state.wallet_address.toLowerCase()
                                                    ?   'You'
                                                    :   (state.game_selected.isSponsored && state.game_selected.address1.toLowerCase()==="0x0000000000000000000000000000000000000000" )
                                                            ?   'Waiting for random'
                                                            :   state.game_selected.address2.toLowerCase()==='0x0000000000000000000000000000000000000000'?'Waiting for opponent':'Opponent'
                                                  }</span>                                            
                                        </div>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <span className="score w150" ref={refScore1}>{state.game_selected.score1}</span>
                                            <span className="score-hyphen w150">-</span>
                                            <span className="score w150" ref={refScore2}>{state.game_selected.score2}</span>
                                        </div>                                
                                    </Card.Body>                                    
                                </Card>}
                                                                
                                {state.game_selected.isSponsored &&
                                    <Card style={{width:'100%', marginTop:10, backgroundColor:'rgba(255, 180, 180, 0.8)', borderColor:'#787878' }}>
                                        <Card.Header as="h5">Sponsored</Card.Header>
                                        <Card.Body>
                                            <div className="d-flex flex-row align-items-center justify-content-around flex-wrap mb-4">
                                                
                                                { msjInfo() }
                                                
                                                <div className="d-flex flex-column flex-wrap align-items-center justify-content-around"  style={{marginTop:10}}>
                                                    <img id="gamepage-sponsored-img" src={sponsoredImage} onError={({ currentTarget }) => {currentTarget.onerror=null; currentTarget.src="./sponsored/default.png";}} />
                                                    {
                                                        sponsoredJSONData &&                                                        
                                                            <ListGroup>
                                                                { sponsoredJSONData.title   && <ListGroup.Item className="bg-transparent" style={{fontWeight:'bold'}}>{sponsoredJSONData.title}</ListGroup.Item>  }
                                                                { sponsoredJSONData.url     && <ListGroup.Item className="bg-transparent"><a className="text-muted text-decoration-none" target="_blank" href={sponsoredJSONData.url} rel="noreferrer">{sponsoredJSONData.urlText}</a></ListGroup.Item>}
                                                                { sponsoredJSONData.summary && <ListGroup.Item className="bg-transparent font-weight-light"><small>{sponsoredJSONData.summary}</small></ListGroup.Item>}
                                                            </ListGroup>                                                            
                                                        
                                                    }
                                                </div>

                                                {   state.game_selected.address1==="0x0000000000000000000000000000000000000000" &&
                                                    <div className="d-flex flex-column flex-wrap align-items-center">
                                                        <span className="mb-1 mt-1">This game will start in</span>
                                                        <div id="sponsoredStartTimeFlipDown" className="flipdown"></div>                                                    
                                                    </div>
                                                }
                                                
                                            </div>
                                            
                                        </Card.Body>                                        
                                    </Card>
                                }
                                <Card style={{width:'100%', marginTop:10, backgroundColor:'rgba(255, 248, 180, 0.8)', borderColor:'#787878' }}>
                                    <Card.Body>
                                        {state.game_selected.sponsoredMinBalance>0 && state.game_selected.isSponsored && <Badge className="text-dark info-bg">Min balance to request this game is {state.game_selected.sponsoredMinBalance/10**state.game_selected.tokenDecimals*1} TAC</Badge>}
                                        <div className="d-flex justify-content-around align-items-center">
                                            <span>Award: {((state.game_selected.isSponsored?state.game_selected.amount:state.game_selected.amount*2)/10**state.game_selected.tokenDecimals)*1} {state.game_selected.tokenSymbol}</span>
                                        </div>
                                    </Card.Body> 
                                </Card>
                                {state.game_selected.isSponsored ?
                                <Card style={{width:'100%', marginTop:10, borderColor:'#787878' }}>
                                    <Card.Body>
                                        
                                        <div className="d-flex justify-content-around align-items-center">
                                            <h1>How to Play</h1>
                                           
                                        </div>
                                        <div className="d-flex justify-content-around align-items-center">
                                        <ul style={{textAlign:'left' }}>
                                            <li>Send a request to join game with free</li>
                                            <li>If you are one of the randomly selected players when time runs out</li>   
                                            <li>Pick heads or tails</li>
                                            <li>Winner of two correct picks wins the prize</li>
                                            <li>Make sure you have BNB in your wallet for transaction fees</li>
                                            
                                            </ul>
                                        </div>
                                    </Card.Body> 
                                </Card>
                                :
                                    <Card style={{width:'100%', marginTop:10, borderColor:'#787878' }}>
                                    <Card.Body>
                                        
                                        <div className="d-flex justify-content-around align-items-center">
                                            <h1>How to Play</h1>
                                           
                                        </div>
                                        <div className="d-flex justify-content-around align-items-center">
                                        <ul style={{textAlign:'left' }}>
                                            <li>Join a game (Provided that you pay the fee set by the player who created the game)</li>
                                            <li>Pick heads or tails</li>
                                            <li>Winner of two correct picks wins the prize</li>
                                            <li>Make sure you have BNB in your wallet for transaction fees</li>
                                            <li><span style={{color:'red'}}>Risk warning: You may lose the entire amount you used during the game.</span></li>
                                            </ul>
                                        </div>
                                    </Card.Body> 
                                </Card>
                                }
                               
                                <div style={{height:160}}></div>
                                <div className="footer fixed-bottom d-flex flex-wrap align-items-center justify-content-around p-1" style={{minHeight:58, backgroundColor:'#f2f2f2'}}>
                                    {prepareBtns()}
                                </div>

                                </div>
                        }
                    </div>
        }
         {isExploding && 
            <div id="winDiv" style={{position:'absolute',top: '50%', left: '50%',zIndex:9999,transform:'translate(-50%,-50%)'}}><span style={{color:'red'}} className="score w150">YOU WIN</span></div>
        }
    </div>

    
  );
}
import { React, useState, useEffect, useContext, useRef } from "react";
import { AppContext } from "../App";
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { addUser } from "../db";
import Stepper from "react-stepper-horizontal";

export default function ImportWallet() {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const connectWalletButtonOnClick = () => {
    addUser("Wallet");
    dispatch({ type: "wallet_type", payload: "Wallet" });
    dispatch({ type: "isLogon", payload: true });
  };
  const steps = [
    { title: "Confirm secret recovery phrase" },
    { title: "Create password" },
  ];
  const [activeStep, setStep] = useState(0);

  return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
      <div
        className="d-flex flex-column text-center justify-content-center align-items-center"
        style={{
          maxWidth: 600,
          marginTop: 50,
          padding: 25,
          border: "1px solid rgb(222, 226, 230)",
        }}
      >
       <div  className="" style={{width:'110%'}}>
        <Button
          size="lg"
          style={{ borderRadius: 25, float: 'left' }}
          variant="info"
          onClick={() => {
            navigate('/login');
          }}
          className=""
          
        >
          <span style={{ color: "white", marginRight: 5 }}>Back</span>
        </Button>
          <img className="logo-light" src="/logo.png" alt="logo" style={{float:'right'}}/>
        </div>
      


        <h3 style={{ marginTop: 20 }}>Import Wallet</h3>
        <div>
          <Stepper steps={steps} activeStep={activeStep} />
        </div>
        {activeStep === 0 ? (
          <>
            <p>
              Toss a Coin cannot recover your password. We will use your Secret
              Recovery Phrase to validate your ownership, restore your wallet
              and set up a new password. First, enter the Secret Recovery Phrase
              that you were given when you created your wallet.
            </p>
            <p>
              <b>Secret Recovery Phrase (12-word)</b>
            </p>
            <Button
              size="lg"
              style={{ borderRadius: 25, marginTop: 50 }}
              variant="info"
              onClick={() => {
                setStep(1);
              }}
              className=""
            >
              <span style={{ color: "white", marginRight: 5 }}>
                Confirm Secret Recovery Phrase
              </span>
            </Button>{" "}
          </>
        ) : (
          <>
            <p>test</p>
          </>
        )}
      </div>
    </div>
  );
}

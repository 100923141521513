import React from "react";
import {Card} from "react-bootstrap";

const ethers = require('ethers');

export default function ResetWallet() {
 

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Card
        border="success"
        style={{ width: "90%", backgroundColor: "rgba(255,255,255,0.8)" }}
      >
        <Card.Header as="h5">Reset Wallet</Card.Header>
          <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
          
          <div className="mm-box main-container-wrapper">
    <div className="box first-view-main-wrapper box--flex-direction-row">
        <div className="box first-view-main box--flex-direction-row">
            <div className="box import-account box--flex-direction-row">
                <a className="import-account__back-button" href="#">&lt; Back</a>
                <p className="box box--margin-top-1 box--margin-bottom-1 box--flex-direction-row typography typography--p typography--weight-normal typography--style-normal typography--color-text-default">
                    Toss a Coin does not keep a copy of your password. If you’re having trouble unlocking your account, you will need to reset your wallet. You can do this by providing the Secret Recovery Phrase you used when you set up your
                    wallet.
                </p>
                <p className="box box--margin-top-4 box--margin-bottom-4 box--flex-direction-row typography typography--p typography--weight-normal typography--style-normal typography--color-text-default">
                    <span>
                        This action will delete your current wallet and Secret Recovery Phrase from this device. After resetting with a Secret Recovery Phrase, you’ll see a list of accounts
                        based on the Secret Recovery Phrase you use to reset. This new list will automatically include accounts that have a balance. You’ll also be able to
                        <a className="button btn-link import-account__link" target="_blank" rel="noopener noreferrer" href="https://metamask.zendesk.com/hc/en-us/articles/360015489271">re-add any other accounts</a> created previously. Custom
                        accounts that you’ve imported will need to be <a className="button btn-link import-account__link" target="_blank" rel="noopener noreferrer" href="https://metamask.zendesk.com/hc/en-us/articles/360015489331">re-added</a>,
                        and any custom tokens you’ve added to an account will need to be
                        <a className="button btn-link import-account__link" target="_blank" rel="noopener noreferrer" href="https://metamask.zendesk.com/hc/en-us/articles/360015489031">re-added</a> as well.
                    </span>
                </p>
                <p className="box box--margin-top-1 box--margin-bottom-4 box--flex-direction-row typography typography--p typography--weight-normal typography--style-normal typography--color-text-default">
                    Make sure you’re using the correct Secret Recovery Phrase before proceeding. You will not be able to undo this.
                </p>
                <form className="create-new-vault__form">
                    <div className="import-srp__container">
                        <label className="import-srp__srp-label"><h4 className="box mm-text mm-text--heading-sm box--flex-direction-row box--color-text-default" align="left">Secret Recovery Phrase (12-word)</h4></label>
                        <div className="actionable-message import-srp__paste-tip actionable-message--with-icon">
                            <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 0C2.2 0 0 2.2 0 5s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5zm0 2c.4 0 .7.3.7.7s-.3.7-.7.7-.7-.2-.7-.6.3-.8.7-.8zm.7 6H4.3V4.3h1.5V8z" fill="var(--color-info-default)"></path>
                            </svg>
                            <div className="actionable-message__message">You can paste your entire secret recovery phrase into any field</div>
                        </div>
                       
                        <div className="import-srp__srp">
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-0" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">1.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-0" type="password" dir="auto" data-testid="import-srp__srp-word-0" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-0-checkbox" type="checkbox" data-testid="import-srp__srp-word-0-checkbox" />
                                    <label for="import-srp__srp-word-0-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-1" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">2.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-1" type="password" dir="auto" data-testid="import-srp__srp-word-1" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-1-checkbox" type="checkbox" data-testid="import-srp__srp-word-1-checkbox" />
                                    <label for="import-srp__srp-word-1-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-2" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">3.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-2" type="password" dir="auto" data-testid="import-srp__srp-word-2" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-2-checkbox" type="checkbox" data-testid="import-srp__srp-word-2-checkbox" />
                                    <label for="import-srp__srp-word-2-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-3" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">4.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-3" type="password" dir="auto" data-testid="import-srp__srp-word-3" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-3-checkbox" type="checkbox" data-testid="import-srp__srp-word-3-checkbox" />
                                    <label for="import-srp__srp-word-3-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-4" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">5.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-4" type="password" dir="auto" data-testid="import-srp__srp-word-4" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-4-checkbox" type="checkbox" data-testid="import-srp__srp-word-4-checkbox" />
                                    <label for="import-srp__srp-word-4-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-5" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">6.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-5" type="password" dir="auto" data-testid="import-srp__srp-word-5" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-5-checkbox" type="checkbox" data-testid="import-srp__srp-word-5-checkbox" />
                                    <label for="import-srp__srp-word-5-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-6" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">7.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-6" type="password" dir="auto" data-testid="import-srp__srp-word-6" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-6-checkbox" type="checkbox" data-testid="import-srp__srp-word-6-checkbox" />
                                    <label for="import-srp__srp-word-6-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-7" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">8.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-7" type="password" dir="auto" data-testid="import-srp__srp-word-7" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-7-checkbox" type="checkbox" data-testid="import-srp__srp-word-7-checkbox" />
                                    <label for="import-srp__srp-word-7-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-8" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">9.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-8" type="password" dir="auto" data-testid="import-srp__srp-word-8" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-8-checkbox" type="checkbox" data-testid="import-srp__srp-word-8-checkbox" />
                                    <label for="import-srp__srp-word-8-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-9" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">10.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-9" type="password" dir="auto" data-testid="import-srp__srp-word-9" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-9-checkbox" type="checkbox" data-testid="import-srp__srp-word-9-checkbox" />
                                    <label for="import-srp__srp-word-9-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-10" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">11.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-10" type="password" dir="auto" data-testid="import-srp__srp-word-10" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-10-checkbox" type="checkbox" data-testid="import-srp__srp-word-10-checkbox" />
                                    <label for="import-srp__srp-word-10-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                            <div className="import-srp__srp-word">
                                <label for="import-srp__srp-word-11" className="import-srp__srp-word-label"><p className="box mm-text mm-text--body-md box--flex-direction-row box--color-text-default">12.</p></label>
                                <div className="MuiFormControl-root MuiTextField-root">
                                    <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                        <input aria-invalid="false" autocomplete="off" id="import-srp__srp-word-11" type="password" dir="auto" data-testid="import-srp__srp-word-11" className="MuiInputBase-input MuiInput-input" value="" />
                                    </div>
                                </div>
                                <div className="show-hide-toggle">
                                    <input className="show-hide-toggle__input" id="import-srp__srp-word-11-checkbox" type="checkbox" data-testid="import-srp__srp-word-11-checkbox" />
                                    <label for="import-srp__srp-word-11-checkbox" className="show-hide-toggle__label" title="Show/Hide this word of the secret recovery phrase">
                                        <svg width="24" height="24" fill="currentColor" className="show-hide-toggle__icon" aria-label="This word is hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                            <path
                                                d="M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z"
                                            ></path>
                                        </svg>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="create-new-vault__create-password">
                        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal">
                            <label className="MuiFormLabel-root MuiInputLabel-root jss9 jss13 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" for="password" id="password-label">
                                New password (8 characters min)
                            </label>
                            <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                <input aria-invalid="false" autocomplete="new-password" id="password" type="password" dir="auto" data-testid="create-vault-password" className="MuiInputBase-input MuiInput-input" value="" />
                            </div>
                        </div>
                        <div className="MuiFormControl-root MuiTextField-root MuiFormControl-marginNormal">
                            <label className="MuiFormLabel-root MuiInputLabel-root jss9 jss13 MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true" for="confirm-password" id="confirm-password-label">
                                Confirm password
                            </label>
                            <div className="MuiInputBase-root MuiInput-root jss12 MuiInputBase-formControl MuiInput-formControl">
                                <input aria-invalid="false" autocomplete="new-password" id="confirm-password" type="password" dir="auto" data-testid="create-vault-confirm-password" className="MuiInputBase-input MuiInput-input" value="" />
                            </div>
                        </div>
                    </div>
                    <button className="button btn--rounded btn-primary create-new-vault__submit-button" data-testid="create-new-vault-submit-button" disabled="" type="submit">Restore</button>
                </form>
            </div>
        </div>
    </div>
</div>

        
          </Card.Body>
       
      </Card>
     
    </div>
  );
}

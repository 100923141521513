import { useContext } from "react";
import { Button,OverlayTrigger, Tooltip } from 'react-bootstrap';
import {  FaCircle } from 'react-icons/fa';
import { AppContext } from "../App";


export default function ConnectWalletButton(){
    const {state, dispatch} = useContext(AppContext);
    const connectButtonOnClick = () => {
        dispatch({type:'modal_show_state', payload:true});
    }

    const getAddress =(addr) => addr[0]+addr[1]+addr[2]+addr[3]+addr[4]+addr[5]+'...'+addr[addr.length-3]+addr[addr.length-2]+addr[addr.length-1];
    

    return (
        state.wallet_address ?
            <OverlayTrigger placement="bottom" delay={{ show: 0, hide: 50 }} overlay={<Tooltip id="button-tooltip">{state.wallet_address}</Tooltip>}>                    
                <>
                <Button size="sm" style={{borderRadius:25}} variant='info' onClick={connectButtonOnClick} className="d-none d-lg-inline">
                    <span style={{fontSize:'x-small', color:'#575454', marginRight:5}}>
                        {(state.wallet_address) ? getAddress(state.wallet_address) : 'Connect Wallet'}
                    </span> 
                    <FaCircle color={(state.wallet_state==='NOT_CONNECTED')?'tomato':(state.wallet_state==='CONNECTED')?'lightgreen':'orange'} />
                </Button>
                <Button size="lg" style={{borderRadius:25, marginBottom:10}} variant='info' onClick={connectButtonOnClick} className="d-lg-none">
                    <span style={{color:'#575454', marginRight:5}}>
                        {(state.wallet_address) ? getAddress(state.wallet_address) : 'Connect Wallet'}
                    </span> 
                    <FaCircle color={(state.wallet_state==='NOT_CONNECTED')?'tomato':(state.wallet_state==='CONNECTED')?'lightgreen':'orange'} />
                </Button>
                </>
            </OverlayTrigger>
        :   <>
            <Button size="sm" style={{borderRadius:25}} variant='info' onClick={connectButtonOnClick} className="d-none d-lg-inline">
                <span style={{fontSize:'x-small', color:'#575454', marginRight:5}}>
                    {(state.wallet_address) ? getAddress(state.wallet_address) : 'Connect Wallet'}
                </span> 
                <FaCircle color={(state.wallet_state==='NOT_CONNECTED')?'tomato':(state.wallet_state==='CONNECTED')?'lightgreen':'orange'} />
            </Button>
            <Button size="lg" style={{borderRadius:25, marginBottom:10}} variant='info' onClick={connectButtonOnClick} className="d-lg-none">
                <span style={{color:'#575454', marginRight:5}}>
                    {(state.wallet_address) ? getAddress(state.wallet_address) : 'Connect Wallet'}
                </span> 
                <FaCircle color={(state.wallet_state==='NOT_CONNECTED')?'tomato':(state.wallet_state==='CONNECTED')?'lightgreen':'orange'} />
            </Button>
            </>
    )
}


import { useContext } from "react";
import { Button } from 'react-bootstrap';
import {  useNavigate  } from "react-router-dom";
import { AppContext } from "../App";


export default function NewGameButton(){
    const {state, dispatch} = useContext(AppContext);
    const navigate = useNavigate(); 
    
    const btnClick = () => {
        dispatch({type:'modal_newGame_show_state', payload:true});        
        navigate(`/`);
    }

    return (        
        <>
            <Button size="sm" variant='success' style={{marginRight:15}} onClick={btnClick} className="d-none d-lg-inline">
                <span style={{fontSize:'x-small'}}>Create A Game</span> 
            </Button>
            <Button size="lg" variant='success' style={{marginBottom:10}} onClick={btnClick} className="d-lg-none">
                <span>Create A Game</span>
            </Button>
        </>        
    )
}
import {React,useState, useEffect, useContext, useRef}  from "react";
import { AppContext } from "../App";


const DBName='TacDB';

const { v4: uuidv4 } = require('uuid');

export default function DB() {
 
}



export const checkIfRegisteredUser = async () => {
  

 
  //  await deleteUser();
  
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(DBName, 1);
  
      request.onupgradeneeded = event => {
        const db = event.target.result;
        
        // Create the 'users' object store if it doesn't exist
        if (!db.objectStoreNames.contains('user')) {
          const objectStore = db.createObjectStore('user', { keyPath: 'uid' });
          objectStore.createIndex('uid', 'uid', { unique: true });
        }
      };
  
      request.onsuccess = event => {
        const db = event.target.result;
        const transaction = db.transaction(['user'], 'readonly');
        const objectStore = transaction.objectStore('user');
  
        const uidIndex = objectStore.index('uid');
       
  
        const getAllRequest = objectStore.getAll();
  
        getAllRequest.onsuccess = event => {
            const users = event.target.result;
    
            if (users.length > 0) {
              const firstUser = users[0];
             
 //             console.log('İlk kayıt:', firstUser);
//              setWalletType(firstUser.type);
              resolve(firstUser);
            } else {
              
        //   addUser();  
              
              resolve(false);
            }
          };
    
  
        getAllRequest.onerror = event => {
          console.error('IndexedDB get error:', event.target.error);
          reject(event.target.error);
        };
      };
  

      request.onerror = event => {
        console.error('IndexedDB open error:', event.target.error);
        reject(event.target.error);
      };
    });
  };
  export const addUser = async (type,uid,hash) => {
    return new Promise((resolve, reject) => {
    const uniqueKey =uid ? uid : uuidv4();
    const userToAdd = {
            uid: uniqueKey,
            hash: hash,
            type: type,
    };  
    
    const request = window.indexedDB.open(DBName, 1);
  
      request.onupgradeneeded = event => {
        const db = event.target.result;
  
        if (!db.objectStoreNames.contains('user')) {
            const objectStore = db.createObjectStore('user', { keyPath: 'uid' });
            objectStore.createIndex('uid', 'uid', { unique: true });
        }
      };
  
      request.onsuccess = event => {
        const db = event.target.result;
        const transaction = db.transaction(['user'], 'readwrite');
        const objectStore = transaction.objectStore('user');
  
        const addUserRequest = objectStore.add(userToAdd);
  
        addUserRequest.onsuccess = event => {
          console.log('Kullanıcı başarıyla eklendi:', event.target.result);
          resolve(true);
        };
  
        addUserRequest.onerror = event => {
          console.error('Kullanıcı eklerken hata:', event.target.error);
          reject(event.target.error);
        };
      };
  
      request.onerror = event => {
        console.error('IndexedDB açma hatası:', event.target.error);
        reject(event.target.error);
      };
    });
  };
  const deleteUser = async () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(DBName, 1);
  
      request.onupgradeneeded = event => {
        const db = event.target.result;
  
        if (!db.objectStoreNames.contains('user')) {
            const objectStore = db.createObjectStore('user', { keyPath: 'uid' });
            objectStore.createIndex('uid', 'uid', { unique: true });
        }
      };
  
      request.onsuccess = event => {
        const db = event.target.result;
        const transaction = db.transaction(['user'], 'readwrite');
        const objectStore = transaction.objectStore('user');
  
        const getAllRequest = objectStore.getAll();
  
        getAllRequest.onsuccess = event => {
          const users = event.target.result;
  
          if (users.length > 0) {
            const firstUserId = users[0].uid;
  
            const deleteRequest = objectStore.delete(firstUserId);
  
            deleteRequest.onsuccess = event => {
              console.log('İlk kullanıcı başarıyla silindi.');
              resolve(true);
            };
  
            deleteRequest.onerror = event => {
              console.error('Kullanıcı silinirken hata:', event.target.error);
              reject(event.target.error);
            };
          } else {
            console.log('Silinecek kullanıcı bulunamadı.');
            resolve(false);
          }
        };
  
        getAllRequest.onerror = event => {
          console.error('IndexedDB getAll error:', event.target.error);
          reject(event.target.error);
        };
      };
  
      request.onerror = event => {
        console.error('IndexedDB açma hatası:', event.target.error);
        reject(event.target.error);
      };
    });
  };

import React from "react";
import {Card} from "react-bootstrap";


export default function HowToPlay() {
 
  
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <Card
        border="success"
        style={{ width: "90%", backgroundColor: "rgba(255,255,255,0.8)" }}
      >
        <Card.Header as="h5">How to Play</Card.Header>
          <Card.Body className="flex-wrap justify-content-center align-items-center">
          <ul><li><b>STEP 1:</b> Setup Metamask extension for browser or download Metamask app for mobile
            <p><a target="_blank" rel="noreferrer" href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn">Get Metamask for Chrome</a> </p>
            <p><a target="_blank" rel="noreferrer" href="https://metamask.app.link/bxwkE8oF99">Get Metamask for Android</a> </p>
            <p><a target="_blank" rel="noreferrer" href="https://metamask.app.link/skAH3BaF99">Get Metamask for IPhone</a> </p>
            
          </li>
          <li><p><b>STEP 2:</b> Add BSC network to Metamask</p>
            <p>Network name : BNB Chain</p>
            <p>New RPC URL : https://bsc-dataseed.binance.org/ </p>
            <p>Chain ID : 56 </p>
            <p>Currency symbol : BNB</p>
            <p>Block explorer URL : https://bscscan.com</p>
          </li>
          <li>
          <p><b>STEP 3:</b> Be sure you have BNB in your wallet for transaction fees.</p>
          </li>
          <li>
          <b>STEP 4:</b> Connect wallet
          <p>Click "Connect Wallet" button top right of our app.</p>
          
          </li>
          <li>
          <b>STEP 5:</b> Play Game
          <p>You can now play game clicking "Create a Game" or "Join Game" from listed games below</p>
          <p>ENJOY</p>
          </li>
          </ul>
        
          </Card.Body>
       
      </Card>
     
    </div>
  );
}

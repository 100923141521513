import React, { useState, useEffect, useContext } from "react";
import { Alert,Card, Form,Spinner} from "react-bootstrap";
import { AppContext, tac_contract } from "../App";
import { convertHexToBigInt, objGame,bscProviderUrl } from "../common/const";
import { CgAdd }  from 'react-icons/cg';
import { IoIosHelpCircle } from "react-icons/io";
import GameCard from "../common/GameCard";
import { useNavigate } from 'react-router-dom';

let contractsAddresses = [];
let filter = "";
let sort = "";


export default function Dashboard() {
    const {state, dispatch} = useContext(AppContext);
    const [errorMessage, setError] = useState(null);
    const navigate = useNavigate();    
    useEffect(() => {
       // console.log("Dashboard.js: useEffect()",tac_contract);
        let interval = setInterval(async () => {await getGames()}, 1000);

        state.contracts.map(q=>contractsAddresses.push(q.address));

        return () => {
            // console.log("clearInterval ÇALIŞTI!!!!");
            clearInterval(interval);
        }
    },[]);
    const createSponsoredGameCardClicked = ()=>
    {
        navigate("/besponsored");
    }
    const createGameCardClicked = ()=>
    {
        if(state.wallet_state==='NOT_CONNECTED')
        {
            dispatch({type:'toast',payload:{toast_bg:'danger', toast_header:'Error',
												toast_show:true,   toast_message:'Connect wallet for create new challenge game!',}})

        }else
        {
            dispatch({type:'modal_newGame_show_state', payload:true});
        }
        
    }
    const gameListPrepare = (n) =>{
        let games = []
       n.map(x=>{ 
            if(Number(convertHexToBigInt(x[0]))>0){
                if(filter==="" || x.isSponsored ) games.push(objGame(x))
                else if(filter==="other" && !contractsAddresses.includes(x.token)) games.push(objGame(x))
                else if(x.token.toLowerCase()===filter.toLowerCase()) games.push(objGame(x));
            }
        });

        if(games.length>0){
            if     (sort==='award-highest') games.sort(function (x, y) { return x.amount - y.amount;})
            else if(sort==='award-lowest')  games.sort(function (x, y) { return y.amount - x.amount;}) 
            else games.sort(function (x, y) { return x.gameId - y.gameId;})           
        }

        return games;
    }

    const getGames = async ()=>{
        
      if(!state.game_fetching){
        setError(null);
        try{
            dispatch({type:'game_fetching',payload:true});
            let games = [];
            if(tac_contract){
                let n = await tac_contract.getGamesOnlyOpen();
                
                games = gameListPrepare(n);
                
                //// console.log("dashboard",games, filter,sort);
                dispatch({type:'games',payload: games.reverse()});
    
            }
    
        }catch(e){
            
            // console.log(e.message)
        }
      }
      
    }  

    if (!tac_contract ){
    return (
        <div className="d-flex flex-column text-center justify-content-center align-items-center">
            <Spinner size="sm" animation="grow" />
        </div>
    );
    }else{
    return (
        
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
       
        {!tac_contract ?
       <Card style={{width:'90%', marginBottom:50, backgroundColor:'#faebd780'}}>
            <Card.Header as="h5">Get Started</Card.Header>
            <Card.Body className="flex-wrap justify-content-center align-items-center">
                <h4>Welcome to Toss a Coin</h4>
                <p>You can play our game on Binance Smart Chain (BSC)</p>
                <p><a href="/howtoplay">Click Here</a> to learn about setup</p>
            </Card.Body>
        </Card>
        :null}
        <Card style={{width:'90%', marginBottom:50, backgroundColor:'#faebd780'}}>
            <Card.Header style={{backgroundColor:"#ffd18e"}} as="h5">
            <IoIosHelpCircle onClick={()=>navigate("/helpcreatesponsoredgame")} style={{position:'absolute',left:5,top:5,cursor:'pointer'}} size={30} color="#647564" title="What is Sponsored Games" />
           
                Sponsored Games</Card.Header>
            <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
            <Card className="bg-c-yellow" onClick={()=>createSponsoredGameCardClicked()} 
                      style={{width:300, borderColor:'#ffd18e', margin:13, cursor:'pointer'}}>
                    <Card.Body >
                            <p><CgAdd size={50} color="green" title="Create A Game" /></p>
                           Create New Sponsored Game
                    </Card.Body>
                </Card>
                
                {!tac_contract 
                    ? <>
                        <Alert className="w-100 d-none d-sm-block" variant="danger"> The games could not be listed. Wallet not connected!</Alert>
                        
                      </>
                        : state.games.filter((g)=> g.isSponsored ).length===0 
                            ? <Alert className="w-100" variant="info">The sponsored game is currently not available. You can try challenge games.</Alert>
                            : state.games.filter(g => g.isSponsored ).map((game,i) => <GameCard key={i}  game={game} /> )
                }
                
            </Card.Body>
        </Card>
                   
        <Card style={{width:'90%', marginBottom:50, backgroundColor:'#c1fff26e'}}>
            <Card.Header style={{backgroundColor:"#81ffe5"}} as="h5">
            <IoIosHelpCircle onClick={()=>navigate("/helpcreategame")} style={{position:'absolute',left:5,top:5,cursor:'pointer'}} size={30} color="#647564" title="What is Challange Games" />
                Challenge Games {state.wallet_state!=='NOT_CONNECTED' && <CgAdd onClick={()=>dispatch({type:'modal_newGame_show_state', payload:true})} style={{position:'absolute',right:5,top:5,cursor:'pointer'}} size={30} color="#647564" title="Create A Game" />}</Card.Header>
            <Card.Body className="d-flex flex-wrap justify-content-center align-items-center">
            <Card className="bg-c-green" onClick={()=>createGameCardClicked()} 
                      style={{width:300, borderColor:'#ffd18e', margin:13, cursor:'pointer'}}>
                    <Card.Body >
                            <p><CgAdd size={50} color="#647564" title="Create A Game" /></p>
                           Create New Challenge Game
                    </Card.Body>
                </Card>
            
            {!tac_contract 
                    ? <Alert className="w-100" variant="danger"> The games could not be listed. Wallet not connected!</Alert>
                        : (state.games.filter((g)=> !g.isSponsored ).length===0 && filter==='') 
                            ? <Alert className="w-100" variant="info"> Games not found!</Alert>
                            : <>
                                <div className="d-flex flex-wrap w-100 justify-content-around align-items-center">
                                    <div className="d-flex align-items-center mb-3">   
                                        <span style={{marginRight:10}}>Filter: </span>
                                        <Form.Select size="sm" onChange={e=>{filter=e.target.value}} className="filter-tag">                                            
                                            <option value="">All</option>
                                            {state.contracts.map((c,i)=>(<option key={i} value={c.address}>{c.name} {c.symbol}</option>))}
                                            <option value="other">Other</option>
                                        </Form.Select>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <span style={{marginRight:10}}>Sort: </span>
                                        <Form.Select size="sm" onChange={e=>{sort=e.target.value}} className="filter-tag">
                                            <option value="">Game No: newly added</option>
                                            <option value="award-lowest">Award: lowest first</option>
                                            <option value="award-highest">Award: highest first</option>
                                        </Form.Select>
                                    </div>                                
                                </div>
                                
                                {state.games.filter(g => !g.isSponsored).map((game,i) => <GameCard  key={i} game={game} /> )}
                              </>                         
                }
            </Card.Body>
        </Card>
        
    <div className="footer fixed-bottom d-flex flex-wrap align-items-center justify-content-around p-1" style={{minHeight:30, color:'red'}}>
    {errorMessage ? errorMessage:null}
    </div>    
    <div className="footer d-flex flex-wrap align-items-center justify-content-around p-1" style={{minHeight:10, color:'gray',fontSize:"11px"}}>
    ©2024 Toss a Coin , All rights reserved 
    </div>    
    </div>

    
  );
        }
}

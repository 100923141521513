import {React,useState, useEffect, useContext, useRef}  from "react";
import { AppContext } from "../App";
import {Button} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import {addUser} from '../db';
export default function Login() {
  const {state, dispatch} = useContext(AppContext);
  const navigate = useNavigate();
    const connectWalletButtonOnClick = () => {
        addUser('Metamask');
        dispatch({type:'wallet_type', payload:'Metamask'});
        dispatch({type:'isLogon', payload:true});

        
    }
  if(state.wallet_type==='Wallet')
  {
    return(
      <div className="d-flex flex-column text-center justify-content-center align-items-center">
      <div className="d-flex flex-column text-center justify-content-center align-items-center" style={{maxWidth:600,marginTop:50,padding:25,border:'1px solid rgb(222, 226, 230)'}}>
      <img className="logo-light" src="/logo2x.png" alt="logo"/>
       <h3 style={{marginTop:20}}>Welcome to Decentralized Mini Games</h3>
       Please Login
       <div className="text-center justify-content-center align-items-center" >
           <Button size="lg" style={{borderRadius:25, marginTop:50}} variant='info' onClick={() => {navigate('/createwallet')}} className="">
             <span style={{color:'white', marginRight:5}}>
                 Create a Wallet
             </span> 
           </Button>
           
        </div>  
        
           </div>
     </div>
    );
  }else
  return (
    <div className="d-flex flex-column text-center justify-content-center align-items-center">
     <div className="d-flex flex-column text-center justify-content-center align-items-center" style={{maxWidth:600,marginTop:50,padding:25,border:'1px solid rgb(222, 226, 230)'}}>
     <img className="logo-light" src="/logo2x.png" alt="logo"/>
      <h3 style={{marginTop:20}}>Welcome to Decentralized Mini Games</h3>
      How would you like to continue?
      <div className="text-center justify-content-center align-items-center" >
          <Button size="lg" style={{borderRadius:25, marginTop:50}} variant='info' onClick={() => {navigate('/createwallet')}} className="">
            <span style={{color:'white', marginRight:5}}>
                Create a Wallet
            </span> 
          </Button>
          
          <Button size="lg" style={{borderRadius:25, marginTop:50, marginLeft:10}} variant='info' onClick={() => {navigate('/importwallet')}} className="">
            <span style={{color:'white', marginRight:5}}>
               Import Wallet
            </span> 
          </Button>
          </div>
          <span style={{color:'black', marginTop:20}}>
              or
          </span>   
          <Button size="lg" style={{borderRadius:25, marginTop:20, marginLeft:10}} variant='info' onClick={() => {connectWalletButtonOnClick()}} className="">
            <span style={{color:'white', marginRight:5}}>
               Continue with Wallet Connect
            </span> 
          </Button>
          </div>
    </div>
  );
}

import { Container, Nav, Navbar, Button } from 'react-bootstrap';

import React,{ useContext, useState, useRef, useEffect  } from 'react';
import ConnectWalletButton from './ConnectWalletButton';
import { useNavigate } from 'react-router-dom';
import NewGameButton from './NewGameButton';
import { AppContext } from '../App';

function HeaderBar() {
    const {state} = useContext(AppContext);
    const [expanded, setExpanded] = useState(false);
    const navbarRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Component mount olduğunda event listener eklenir
        document.addEventListener('click', handleClickOutside);
        return () => {
          // Component unmount olduğunda event listener kaldırılır
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    
    const handleClickOutside = (event) => {        
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
          // Tıklanan element navbarın dışında ise menüyü kapatır
          setExpanded(false);
        }
    };

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const navigateAndUnexpanded = (route) => {
        if(expanded) handleToggle();
        navigate(route);
    };

    return (
        <Navbar expand="lg" expanded={expanded} ref={navbarRef}>
            <Container fluid className="d-flex">
                <Navbar.Brand id="navbar-logo" style={{cursor:'pointer'}} onClick={()=>navigateAndUnexpanded(`/`)}><img className="logo-light" src="/logo.png" alt="logo"/></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle} />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                    <Button size="sm" variant='warning' style={{marginRight:15}} onClick={()=>navigate(`/`)} className='d-none d-lg-inline'>
                        <span style={{fontSize:'x-small'}}>Home</span>
                    </Button>

                    <Button size="lg" variant='warning' style={{marginBottom:10}} onClick={()=>navigateAndUnexpanded(`/`)} className='d-lg-none'>
                        <span>Home</span>
                    </Button>

                    <Button size="sm" variant='warning' style={{marginRight:15}} onClick={()=>navigate(`/howtoplay`)} className='d-none d-lg-inline'>
                        <span style={{fontSize:'x-small'}}>How to Play</span>
                    </Button>
                    <Button size="lg" variant='warning' style={{marginBottom:10}} onClick={()=>navigateAndUnexpanded(`/howtoplay`)} className='d-lg-none'>
                        <span>How to Play</span>
                    </Button>

                    {state.wallet_address && <NewGameButton />}

                    {state.wallet_address && 
                    <>
                        <Button size="sm" variant='success' style={{marginRight:15}} onClick={()=>navigate(`/my-games`)} className='d-none d-lg-inline'>
                            <span style={{fontSize:'x-small'}}>My Games</span>
                        </Button>
                        <Button size="lg" variant='success' style={{marginBottom:10}} onClick={()=>navigateAndUnexpanded(`/my-games`)} className='d-lg-none'>
                            <span>My Games</span>
                        </Button>
                    </>}

                    <Button size="sm" variant='primary' style={{marginRight:15}} onClick={()=>navigate(`/demo`)} className='d-none d-lg-inline'>
                        <span style={{fontSize:'x-small'}}>Am I Lucky?</span>
                    </Button>
                    <Button size="lg" variant='primary' style={{marginBottom:10}} onClick={()=>navigateAndUnexpanded(`/demo`)} className='d-lg-none'>
                        <span>Am I Lucky</span>
                    </Button>

                    <ConnectWalletButton />
                    
                </Nav>
                </Navbar.Collapse>
            </Container>
            {/*<Container fluid className='flex-column flex-sm-row'>
                <Navbar.Brand id="navbar-logo" style={{cursor:'pointer'}} onClick={()=>navigate(`/`)}><img className="logo-light" src="/logo.png" alt="logo"/></Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto"></Nav>
                    <Nav>
                        <Button size="sm" style={{marginRight:15}} variant='warning' onClick={()=>navigate(`/`)}>
                            <span style={{fontSize:'x-small', }}>Home</span>
                        </Button>   
                         <Button size="sm" style={{marginRight:15}} variant='warning' onClick={()=>navigate(`/`)}>
                            <span style={{fontSize:'x-small', }}>Buy TAC</span>
                        </Button>   
                        <Button size="sm" style={{marginRight:15}} variant='warning' onClick={()=>navigate(`/howtoplay`)}>
                            <span style={{fontSize:'x-small', }}>How to Play</span>
                        </Button>
                        <NewGameButton />
                        <Button size="sm" style={{marginRight:15}} variant='warning' onClick={()=>navigate(`/my-games`)} className={state.wallet_state==='NOT_CONNECTED'?'d-none':''}>
                            <span style={{fontSize:'x-small', }}> My Games</span>
                        </Button>
                        <Button size="sm" style={{marginRight:15}} variant='warning' onClick={()=>navigate(`/demo`)}>
                            <span style={{fontSize:'x-small', }}>Am I Lucky</span>
                        </Button>
                       
                    </Nav>
                    <Nav>   
                        <ConnectWalletButton />
                    </Nav>
                </Navbar.Collapse>
            </Container>*/}
        </Navbar>
)
}



export default HeaderBar;